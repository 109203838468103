import $ from 'jquery';
import { vfde } from '../vfde';

/**
 * if not mobile then change number to text
 * it allows user to use keyDown or keyUp to move background on typeahead propositions
 * otherwise type number will decrease/increase PLZ or consumption!
 *
 * @param  {[string]} jQuery selector to in which find elements
 */
export function convertTypeNumberToText(selector) {
  const $inputs = $(selector);

  Log.debug('converting type number to text for ' + $inputs.length + ' elements');

  // for all found change type...
  $inputs.each((i, el) => {
    Log.debug('converting type number to text for following element:', el);
    $(el).attr('type', 'text');
  });
}

export default convertTypeNumberToText;

vfde.utilities.registerUtility(convertTypeNumberToText, 'convertTypeNumberToText');
