import { Log } from '../utilities/Logger';

export function bfCacheLoad() {
  window.onpageshow = function (event) {
    if (event.persisted) {
      Log.debug('browser back-forward cache detected --> refresh!');
      window.location.reload();
    } else {
      Log.debug('browser back-forward cache not detected');
    }
  };
}
