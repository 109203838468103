import vfde from '../vfde';
/*
  Determine if the element is visible or not
  can be used as a replaced for  $(element).is(':visible') in jQuery
*/
export function isVisible(elem) {
  return (
    elem.offsetWidth > 0 || elem.offsetHeight > 0 || elem.getClientRects().length > 0
  );
}

export default isVisible;

vfde.utilities.registerUtility(isVisible, 'isVisible');
