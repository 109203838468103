import vfde from '../vfde';
import formValidate from '../utilities/formValidate';
import { checkInputMask } from '../utilities/formInputMask';

// add protection for double or more submits
export function protectForms() {
  // TODO: rewrite the modules below into ES6 syntax and import them separately
  vfde.utilities.formSubmit('form');
  formValidate(document.querySelectorAll('[data-validateForm]'));
  checkInputMask(document.querySelectorAll('[data-mask]'));
  vfde.utilities.formValidateAjax('[data-validateFormAjax]');
  vfde.utilities.resizableIframe('[data-resizableIframe]');
  vfde.utilities.iButton('.iButton__icon');
}
