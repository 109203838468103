import vfde from '../vfde';

/**
 * format function used for formating tariff value
 * @param  {[type]} n
 * @return {[type]} formated value
 */
export function format(n) {
  return n
    .toFixed(0) // always two decimal digits
    .replace('.', ',') // replace decimal point character with ,
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.'); // use . as a separator
}

// TODO: remove after no vfde.utilities.format is used (in favour of importing the above function)
vfde.utilities.registerUtility(format, 'format');
