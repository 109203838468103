(function (vfde) {
  /**
   * consumptionMobileControllerGas: functionality for consumption (gas) buttons on mobile (reacts also on desktop changes) and adds "kWh/Jahr" String to consumption value
   * @param pContainer {String}: container for consumption buttons on mobile
   * @param pInputSelector {String}: consumption input field
   *
   * Used for slim consumptionCalculator (Gas)
   */

  function consumptionMobileControllerGas(pContainer, pInputSelector) {
    var _that = this;
    this.range = [
      { val: 4500, idx: 1, selector: 'house1' },
      { val: 7500, idx: 2, selector: 'house2' },
      { val: 10500, idx: 3, selector: 'house3' },
      { val: 15000, idx: 4, selector: 'house4' },
      { val: 18750, idx: 4, selector: 'house4' },
      { val: 27000, idx: 4, selector: 'house4' }
    ];
    this.$container = pContainer;
    if (typeof pInputSelector === 'undefined') {
      pInputSelector = '#input__usage--energy';
    }
    this.$consumptionInput = $(pInputSelector);
    if (!this.$consumptionInput.parent().is('span')) {
      _that.$consumptionInput.wrap('<span class="twitter-typeahead"></span>');
    }
    vfde.controllers.register(this, 'consumptionMobileControllerGas');

    // init proper type and listen to PubSub
    vfde.libs.PubSub.subscribe(
      'inputSet',
      vfde.controllers.consumptionMobileControllerGas.reactOnDesktopChange
    );
    // also we need to listen on topic from inputOverlay to catch all options from dropdown list
    vfde.libs.PubSub.subscribe(
      'inputOverlay',
      vfde.controllers.consumptionMobileControllerGas.reactOnDesktopChange
    );

    //also react on direct change
    this.$consumptionInput.on('keyup', function () {
      vfde.controllers.consumptionMobileControllerGas.reactOnDesktopChange(
        {},
        {
          newValue: vfde.controllers.consumptionMobileControllerGas.$consumptionInput.val()
        }
      );
      _that.shiftDataContent();
    });

    // Update consumption value and highlight mobile consumption button on S2 page
    $(window).on('load', function () {
      vfde.controllers.consumptionMobileControllerGas.reactOnDesktopChange(
        {},
        {
          newValue: vfde.controllers.consumptionMobileControllerGas.$consumptionInput.val()
        }
      );
      _that.shiftDataContent();
    });
  }
  consumptionMobileControllerGas.prototype.shiftDataContent = function () {
    var _that = this;
    if (_that.$consumptionInput.val() == '') {
      _that.$consumptionInput.parent().attr('data-content', '');
    } else {
      // do not work little pity
      //var whiteSpaces = new Array(_that.$consumptionInput.val().length + 1).join(' ');
      var newClass = 'twitter-typeahead-digit-' + _that.$consumptionInput.val().length;
      //reset classes
      _that.$consumptionInput.parent().attr('class', '');
      // add proper class
      _that.$consumptionInput.parent().attr('class', 'twitter-typeahead ' + newClass);
      _that.$consumptionInput.parent().attr('data-content', 'kWh/Jahr');
    }
  };

  consumptionMobileControllerGas.prototype.reactOnDesktopChange = function (msg, data) {
    //var _that = this;
    //pubsub will have different contex!
    var _that = vfde.controllers.consumptionMobileControllerGas;
    var foundInRange = false;
    // as old behaviour that toggleOverlay can send rubbish just protect against it
    if (typeof data.newValue === 'undefined') {
      if (data.action === 'close') {
        _that.$container.find('div').removeClass('isActive');
      }
      _that.shiftDataContent();
      return;
    }
    data.newValue = parseInt(data.newValue, 10);
    _that.$container.find('div').removeClass('isActive');
    for (var i = 1; i < _that.range.length; i++) {
      var prev = i - 1;
      if (
        data.newValue < _that.range[i].val &&
        data.newValue >= _that.range[prev].val
      ) {
        _that.$container
          .find('div:nth-child(' + _that.range[prev].idx + ')')
          .addClass('isActive');
        foundInRange = true;
      }
    }
    // we finish loop and if not found than it means is out of range if above then mark last one
    if (!foundInRange && data.newValue > _that.range[_that.range.length - 1].val) {
      _that.$container.find('div:last-child').addClass('isActive');
    }
    _that.shiftDataContent();
  };

  consumptionMobileControllerGas.prototype.setValue = function (index, $element) {
    var _that = this;
    //increase idx by one
    index++;
    // inner helper
    var _getValue = function (index, currentValue) {
      var newValue = null;
      var valuesForGivenIdx = [];
      for (var i = 0; i < _that.range.length; i++) {
        if (_that.range[i].idx == index) {
          valuesForGivenIdx.push(_that.range[i]);
        }
      }
      if (valuesForGivenIdx.length === 1) {
        newValue = valuesForGivenIdx[0].val;
      } else {
        newValue = valuesForGivenIdx[0].val;
        for (var i = 1; i < valuesForGivenIdx.length; i++) {
          if (
            _that.$consumptionInput.val() < valuesForGivenIdx[i].val &&
            _that.$consumptionInput.val() >= valuesForGivenIdx[i - 1].val
          ) {
            newValue = valuesForGivenIdx[i].val;
            break;
          }
        }
      }
      // update value
      _that.$consumptionInput.val(newValue);
      _that.shiftDataContent();
    };
    _that.$container.find('div').removeClass('isActive');
    $element.addClass('isActive');
    _getValue(index, _that.$consumptionInput.val());
  };

  // Initialization on query selector so it will be done after jquery loaded but before behaviour initialization
  $('[data-js-controller="consumptionMobileControllerGas"]').each(function (index) {
    /** {Object jQuery} - current slider element */
    var $mobileControllerContainer = $(this);
    var config = {};
    // 1. Trying read DOM if failed then default linear configuration
    try {
      var configString = $mobileControllerContainer.attr('config');
      config = JSON.parse(configString);
    } catch (error) {
      config = {
        type: 'energy'
      };
    }
    new consumptionMobileControllerGas($mobileControllerContainer);
    //attach on click events to all childs
    var $childs = $mobileControllerContainer.find('div');
    $childs.each(function () {
      //$(this).click = vfde.controllers.consumptionMobileControllerGas.setValue(index++, $(this));
      $(this).on('click', function () {
        vfde.controllers.consumptionMobileControllerGas.setValue(
          $(this).index(),
          $(this)
        );
      });
    });
  });
})(window.vfde);
