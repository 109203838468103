import $ from 'jquery';
import { Log } from '../utilities/Logger';
import { window } from '../globals';
import vfde from '../vfde';

const hubTiles = hubTile => {
  const mobileBreakpoint = 767;
  const navigationListElementOffset = 10;
  const navigationList = hubTile.querySelector('.hub-tiles__legend__list');
  const navigationListWrapper = hubTile.querySelector(
    '.hub-tiles__legend__list--wrapper'
  );
  const liList = hubTile.querySelectorAll('.hub-tiles__legend ul li');
  const slides = hubTile.querySelectorAll('.hub-tiles__images');
  const legendBar = hubTile.querySelector('.hub-tiles__legend__bar');
  const slickConfig = {
    infinite: false,
    dots: true,
    arrows: false,
    fade: false,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  let mobileDetected = false;
  let elem = '';
  let index = 0;

  const setHubTilesNavigation = () => {
    if (!navigationList) {
      return;
    }
    const action = window.innerWidth <= mobileBreakpoint ? 'click' : 'mouseover';
    liList.forEach(li => {
      //clear events
      li.removeEventListener('mouseover', _navEvents);
      li.removeEventListener('click', _navEvents);

      li.addEventListener(action, _navEvents);
    });
  };

  const _removeActiveClass = () => {
    liList.forEach(li => li.classList.remove('active'));
  };

  const _navEvents = event => {
    elem = event.target;
    index = Array.from(liList).indexOf(elem) + 1;

    _showImages(index);
    _setActiveClass(elem);
  };

  const _setActiveClass = active => {
    // we need to track bar position from desktop in case of orientation change to mobile
    _removeActiveClass();
    active.classList.add('active');
    _adjustLegendBar(active);
  };

  const _showImages = n => {
    //adjust tiles
    slides.forEach(slide => {
      slide.classList.remove(slide.className.match(/hub-tiles__images--Tab-\S+/g));
      slide.classList.add(`hub-tiles__images--Tab-${n}`);
    });
    _lazyLoadPicture(n);
    _lazyLoadMedia(n);
  };

  const _lazyLoadMedia = n => {
    const media = hubTile
      .querySelectorAll('.hub-tiles__images')
      [n - 1].querySelectorAll('[src-lazy]');

    media.forEach(media => {
      media.setAttribute('src', media.getAttribute('src-lazy'));
      media.removeAttribute('src-lazy');
      if (typeof media.tagName === 'string' && media.tagName === 'SOURCE') {
        media.parentElement.addEventListener(
          'canplay',
          event => {
            event.srcElement.previousElementSibling.classList.add('hidden');
            const loadingDiv = hubTile.querySelector('.loading');
            if (loadingDiv && loadingDiv.parentNode) {
              loadingDiv.parentNode.removeChild(loadingDiv);
            }
          },
          false
        );

        media.parentElement.addEventListener(
          'ended',
          event => {
            event.srcElement.previousElementSibling.classList.remove('hidden');
            event.srcElement.setAttribute('style', 'display:none;');
          },
          false
        );

        media.parentElement.load();
      }
    });
  };

  const _lazyLoadPicture = n => {
    const media = hubTile
      .querySelectorAll('.hub-tiles__images')
      [n - 1].querySelectorAll('[srcset-lazy]');

    media.forEach(media => {
      media.setAttribute('srcset', media.getAttribute('srcset-lazy'));
      media.removeAttribute('srcset-lazy');
    });
  };

  const _scrollNavigationListWrapper = scrollTo => {
    let left = navigationListWrapper.scrollLeft;
    let direction = scrollTo > left;
    if (left === scrollTo - 50 || scrollTo <= 20) {
      return;
    }

    const _moveScroll = () => {
      direction ? left++ : left--;
      navigationListWrapper.scrollLeft = left;
      let breakpoint = scrollTo - 50;
      if (direction && left >= breakpoint) {
        clearInterval(id);
      }
      if (!direction && left <= breakpoint) {
        clearInterval(id);
      }
    };
    const id = setInterval(_moveScroll, 0.5);
  };

  const _adjustLegendBar = active => {
    let activeColor = null;
    if (!active) {
      return;
    }
    const activeStyles = getComputedStyle(active);
    activeColor = activeStyles.color;

    // if no active element then adjust legendbar pointless
    if (activeColor) {
      const width =
        active.getBoundingClientRect().width -
        parseInt(activeStyles.paddingLeft) -
        parseInt(activeStyles.paddingRight);
      const left = active.offsetLeft + navigationListElementOffset;
      const cssString = `width: ${width}px; transform: translateX(${left}px); background: ${activeColor}`;

      _scrollNavigationListWrapper(left);

      legendBar.style.cssText = cssString;
    }
  };

  const initSlickOnMobile = () => {
    if (window.innerWidth <= mobileBreakpoint && !mobileDetected) {
      mobileDetected = true;
      slides.forEach(slide => {
        try {
          $(slide).slick(slickConfig);
          Log.info('Slick initialized on element: ', slide);
        } catch (e) {
          Log.error('hubTiles::Slick initialized FAILED on element:', slide);
          Log.error(e);
          // As this controller is being used on article tiles we go on
        }
      });
    }
  };

  //first nav li active adjustment
  const _adjustLegendBarForTheFirstTime = () => {
    if (liList.length) {
      const activeClassSet = new Promise((res, rej) => {
        res(_setActiveClass(liList[0]));
      });

      activeClassSet.then(() => {
        legendBar.classList.add('hub-tiles__legend__bar--animation');
      });
    }
  };

  const carouselResize = () => {
    window.addEventListener('resize', () => {
      setHubTilesNavigation();
      // carousels hide/show based on mobile or not
      if (window.innerWidth > mobileBreakpoint) {
        navigationList
          ? navigationList.classList.remove('hub-tiles__legend__list--open')
          : '';
        slides.forEach(slide => {
          if (slide.classList.contains('slick-initialized')) {
            $(slide).slick('unslick');
            Log.info('Slick destroyed on element: ', slide);
          }
        });
        // adjust correct legend bar based on menu chosen
        let active = hubTile.querySelector('.hub-tiles__legend__list li.active');
        _adjustLegendBar(active);
      }

      initSlickOnMobile();
      // set flag for next orientation change
      mobileDetected = window.innerWidth <= mobileBreakpoint;
    });
  };
  _adjustLegendBarForTheFirstTime();
  setHubTilesNavigation();
  carouselResize();
  initSlickOnMobile();
  _adjustLegendBar();
  _lazyLoadPicture(1);
  _lazyLoadMedia(1);
};

export const initHubTiles = () => {
  const hubs = document.querySelectorAll('[data-js-controller="hubTiles"]');

  hubs.forEach(hub => {
    hubTiles(hub);
  });

  Log.info('hubTiles::initHubTiles found controller containers: ' + hubs.length);
};
