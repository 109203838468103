const resizeContentStencil = image => {
  // const stencilElements = document.getElementsByClassName('stencil');
  const windowWidth = window.innerWidth;
  const stencil = image.closest('.stencil');
  const content = stencil.querySelector('.stencil__content');
  const imageHeight = image.height;
  const contentHeight = content.offsetHeight;

  if (contentHeight > imageHeight && windowWidth > 992) {
    content.style.height = '';
  }
};

const config = {
  rootMargin: '200px 200px 200px 200px'
};

function waitForImageToLoad(imageElement) {
  return new Promise(resolve => {
    imageElement.onload = resolve;
  });
}

let observer = new IntersectionObserver((entries, self) => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      let image = entry.target;

      image.src = image.dataset.src;
      waitForImageToLoad(image).then(() => {
        resizeContentStencil(image);
      });

      self.unobserve(image);
    }
  });
}, config);

export function resizeAndLazyLoadStencilElements() {
  const stencilElements = document.getElementsByClassName('stencil');

  Array.from(stencilElements).forEach(element => {
    const image = element.querySelector('.stencil__image img');
    if (image) {
      observer.observe(image);
    }
  });
}
