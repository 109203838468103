(function (vfde) {
  'use strict';
  /**
   * utility to prevent double submit of forms by disabling submit button
   * @param  {string} element selector
   */
  var formSubmit = function (selector) {
    /** {jQuery object} $elements - jQuery object based on selector */
    var $forms = $(selector),
      /** {jQuery object} $element - single jQuery object from found collection */
      $form;
    $forms.each(function () {
      if (typeof Log === 'object') {
        Log.debug('Form submit protection on form: ', $(this));
      }
      $(this).on('submit', function (/*event*/) {
        $form = $(this);

        var $submit = $form.find('input[type="submit"]');
        if ($submit.length > 0) {
          /* When we find submit button we are copying its name, value and id
          to new hidden field so it will not be skipped in form data as some
          Java application / CMS form can submit data by using submit button
          */
          $('<input/>', {
            id: $submit.attr('id'),
            name: $submit.attr('name'),
            value: $submit.attr('value'),
            type: 'hidden'
          }).appendTo($form);
        } else {
          $submit = $form.find('button');
        }
        if (!$submit.attr('customPrevent')) {
          $submit.attr('disabled', true);
        }
      });
    });
  };

  vfde.utilities.registerUtility(formSubmit, 'formSubmit');
})(window.vfde);
