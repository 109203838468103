import PlaceService from '../vueCommon/services/DI/placeService';

/**
 * Typeahead provide autocomplete functionality in input fields
 * @class Typeahead_place
 */
export class TypeaheadPlace {
  /**
   * Constructor
   * @param {jQuery Object} $element - jquerty object on which we initialize typeahead
   * @param {Object} log - Handler to initialized Logger class
   */
  constructor($element, log, objConfig) {
    /** {jQuery object} - element on which typeahead is binded */
    this.$element = $element;
    /** {jQuery object} - handler to main wrapper of typeahead */
    this.$wrapper = null;
    /** {string} - cache of current value to determine on keyup if there was change of field or not */
    this.currentValue = $element.val();
    /** {string} - cache of current city to determine if send pubsub */
    this.selectedValue =
      $element.val() +
      ' ' +
      ($element.parents('.twitter-typeahead').attr('data-content') || '');
    /** {object} - handler to Logger object */
    this.Log = log;
    /** {object} - configuration of typeahead */
    this.config = {};
    this.me = vfde.utilities.getGUID();
    // init stuff and cross your fingers
    this.readConfig(objConfig);
    this.bindTypeahead();
    this.initDOM();
    this.Log.info('Typeahead created for element: ', $element.attr('id'));
  }
  /**
   * Init DOM attributes and set default value if already passed from CMS side
   */
  initDOM() {
    let _this = this;
    let markup =
      "<div class='input__overlay--white'><ul id=" +
      _this.me +
      " class='input__overlay__items'></ul></div>";
    /** update DOM attributes */
    _this.$element.attr('autocomplete', 'off');
    _this.$element.attr('spellcheck', 'false');
    if (!_this.$element.parent().is('span')) {
      _this.$element.wrap('<span class="twitter-typeahead"></span>');
    }
    // add typeahead overlay
    _this.$element.closest('.form__control').after(markup);

    // init main wrapper as it is alraedy in dom
    _this.$wrapper = $('#' + _this.me).parent();

    // set default value if present
    if (_this.config.defaultValue && _this.config.defaultValue.zip !== '') {
      _this.$element.val(_this.config.defaultValue.zip);
      _this._adjustCityPosition(_this.config.defaultValue.zip);
      _this.$element
        .parent()
        .attr('data-content', _this.config.defaultValue.city || '');
      // check for quick validation
      if (_this.config.defaultValue.city === '') {
        _this.$element.attr('data-typeahead-force-validation', 'true');
        _this.getResults(_this.$element.val());
      }
    }
    // Protection against enter used when typeahead is open so only selection not form submit
    _this.$element.on('keydown', function (event) {
      if (event.which === 13 && _this.$wrapper.hasClass('input__overlay--show')) {
        event.preventDefault();
      }
      // detection of tab pressed, on keyup not possible to catch
      if (event.which === 9 && _this.$wrapper.hasClass('input__overlay--show')) {
        $('#' + _this.me)
          .parent()
          .removeClass('input__overlay--show');
      }
    });
    //init border case if there is such need
    //data-typeahead-init="true"
    if (_this.$element.attr('data-typeahead-init') === 'true') {
      // This flag is set by CMS means there is border case so trigger behaviour to show border case row
      // 1. Remove flag on DOM level
      _this.$element.removeAttr('data-typeahead-init');

      // 2. Prepare dummy remoteData to trigger behaviour manually
      var dummyRemote = {
        places: [
          {
            city: _this.$element.parents('.twitter-typeahead').attr('data-content'),
            zipCode: _this.$element.val(),
            isBordercase: true
          }
        ]
      };
      // 3. Trigger border case
      document.addEventListener(
        'behaviours:loaded',
        function (e) {
          vfde.libs.PubSub.publish('zip', {
            me: 'typeaheadInit',
            newValue:
              _this.$element.val() +
              ' ' +
              _this.$element.parents('.twitter-typeahead').attr('data-content'),
            data: JSON.stringify(dummyRemote)
          });
          Log.log('Typeahead place: publish on event listener executed');
        },
        false
      );
    }
  }
  /**
   * Read DOM attribute: data-typeahead-config and try parse it, afterwards saved to config attribute
   */
  readConfig(objConfig) {
    this.Log.debug('Typeahead reading config ...');
    // 1. Default config
    let _this = this;
    let config = {
      type: 'places',
      energy: 'electricity',
      topic: '',
      defaultValue: {
        zip: '',
        city: ''
      },
      cache: false
    };
    // Adjusting config object
    config.url = objConfig.url || objConfig.remoteURL || config.url;
    config.cache = objConfig.cache || config.cache;
    config.topic = objConfig.topic || config.topic;
    config.type = objConfig.type || config.type;
    config.defaultValue = objConfig.defaultValue || config.defaultValue;
    config.energy = objConfig.energy || config.energy;
    _this.Log.debug('Typeahead config read: ', config);
    _this.config = config;
  }
  /**
   * DOM attributes reset to default start state
   */
  setStateReset() {
    let _this = this;
    _this.$element.removeAttr('data-typeahead-valid');
    _this.$element.removeAttr('data-typeahead-selected');
    _this.$element.removeAttr('data-typeahead-uknown');
  }
  /**
   * DOM attributes as correct typeahead provided
   */
  setStateValid() {
    let _this = this;
    _this.$element.attr('data-typeahead-valid', 'true');
    _this.$element.removeAttr('data-typeahead-selected');
    _this.$element.removeAttr('data-typeahead-uknown');
  }
  /**
   * DOM attributes as not valid (ex.: not 5 chars, not find result...)
   */
  setStateNotValid() {
    let _this = this;
    _this.$element.attr('data-typeahead-valid', 'false');
    _this.$element.removeAttr('data-typeahead-selected');
    _this.$element.removeAttr('data-typeahead-uknown');
    // also we need to clear city name
    _this.$element.parents('.twitter-typeahead').attr('data-content', '');
    // try to destroy frst typeahead if previous one exists
    // it can happen when user click somewhere when list open
    // or he type another PLZ with just single result
    $('#' + _this.me)
      .parent()
      .removeClass('input__overlay--show');
  }
  /**
   * DOM attributes set as user did not select anything from list
   */
  setStateNotChosen() {
    let _this = this;
    _this.$element.attr('data-typeahead-valid', 'true');
    _this.$element.attr('data-typeahead-selected', 'false');
    _this.$element.removeAttr('data-typeahead-uknown');
  }

  /**
   * On binded element is adding listener to control keyup events
   */
  bindTypeahead() {
    let _this = this;
    _this.$element.on('keyup', function (event) {
      if (_this.$element.val().length < 5) {
        _this.setStateNotValid();
        // if nothing entered or cleared we should remove attribute to not fire two validation once
        // required one and valid one
        if (_this.$element.val().length === 0) {
          _this.setStateReset();
          _this.Log.debug('Typeahead input length =0 so reseting valid flag');
        }
      }
      // make ajax or read value from cache ( as val = 5)
      else {
        // check if changed?
        if (_this.currentValue !== _this.$element.val()) {
          //number click so let's get result
          _this.setStateNotValid();
          _this.Log.debug('Typeahead input length = 5 so getting results');
          _this.getResults(_this.$element.val());
        } else {
          _this.Log.debug('Typeahead input length = 5 but not number');
          _this.handleKeyUp(event);
        }
      }
      _this.currentValue = _this.$element.val();
    });
    _this.Log.debug('Typeahead binded to input field');
  }
  /**
   * Small helper method to return params for ajax call, can be easy overwriten
   * in other typehead types (maybe should take list of params in future)
   * @param  {String} - main search query like street name or plz
   * @ return {object} ex.:{ zipCode: plz }
   */
  urlQuery(query) {
    const energyTypeName =
      document.querySelector("input[name='pct']:checked") !== null
        ? document.querySelector("input[name='pct']:checked").id
        : this.config.energy.toLowerCase();

    const energyTypeStructure = {
      electricity: 'STROM',
      gas: 'GAS'
    };

    return {
      energyType: energyTypeStructure[energyTypeName],
      zipCode: query
    };
  }
  /**
   * "Small" helper method to adjust background based on key pressed
   * @param  {jquery Object} - jquery event object
   */
  handleKeyUp(ev) {
    // variables....
    let _this = this,
      $li = $('#' + _this.me).find('li'),
      allowedKeys = {
        0: 'specialKeys',
        8: 'Backspace',
        13: 'Enter',
        38: 'up arrow',
        40: 'down arrow',
        27: 'escape'
      },
      currentSelected = -1,
      keyPressed = ev.which;
    // checking if it is worth analyzing user input
    if (typeof keyPressed !== 'number') {
      _this.Log.debug('Typeahead handleKeyUp param not number');
      return;
    }
    if (!(keyPressed in allowedKeys)) {
      _this.Log.debug(
        'Typeahead handleKeyUp key pressed is not in allowed one: ',
        keyPressed
      );
      return;
    }

    // gather information if already any 'chosen/highlighed'
    $li.each(function (index) {
      if ($(this).hasClass('input__overlay_item--white-active')) {
        currentSelected = index;
        $(this).removeClass('input__overlay_item--white-active');
      }
    });

    // finallly react based on key pressed
    // DOWN ARROW
    if (keyPressed === 40) {
      _this.Log.debug('Typeahead handleKeyUp, key pressed: arrow down', keyPressed);
      if (currentSelected < 0) {
        $('#' + _this.me)
          .find('li')
          .first()
          .addClass('input__overlay_item--white-active');
      } else {
        currentSelected++;
        if (currentSelected == $li.length) {
          currentSelected = 0;
        }
        $li.eq(currentSelected).addClass('input__overlay_item--white-active');
        // check if we need to adjust scroll...
        adjustScroll('up');
      }
    }
    // UP ARROW
    if (keyPressed === 38) {
      _this.Log.debug('Typeahead handleKeyUp, key pressed: arrow up', keyPressed);
      if (currentSelected < 0) {
        $('#' + _this.me)
          .find('li')
          .last()
          .addClass('input__overlay_item--white-active');
      } else {
        currentSelected--;
        if (currentSelected < 0) {
          currentSelected = $li.length - 1;
        }
        $li.eq(currentSelected).addClass('input__overlay_item--white-active');
        adjustScroll('down');
      }
    }
    // ENTER maybe add right arrow?
    if (keyPressed === 13) {
      _this.Log.debug('Typeahead handleKeyUp, key pressed: Enter', keyPressed);
      // if enter then let's reuse handler we already put there
      $li.eq(currentSelected).click();
    }
    //ESCAPE or BACKSPACE
    if (keyPressed === 27 || keyPressed === 8) {
      _this.Log.debug(
        'Typeahead handleKeyUp, key pressed: Escape or Backspace',
        keyPressed
      );
      // let's just hide when new one is created it is cleared anyway
      $('#' + _this.me)
        .parent()
        .removeClass('input__overlay--show');
    }

    function adjustScroll(direction) {
      if (direction === 'up') {
        if (_this.$wrapper.height() < $li.eq(currentSelected).position().top) {
          _this.$wrapper.scrollTop(
            _this.$wrapper.scrollTop() + $li.eq(currentSelected).position().top
          );
        }
        if ($li.eq(currentSelected).position().top < 0) {
          _this.$wrapper.scrollTop(0);
        }
      } else {
        if ($li.eq(currentSelected).position().top < 0) {
          _this.$wrapper.scrollTop(
            _this.$wrapper.scrollTop() - _this.$wrapper.height()
          );
        }
        if ($li.eq(currentSelected).position().top > _this.$wrapper.height()) {
          _this.$wrapper.scrollTop($li.eq(currentSelected).position().top);
        }
      }
    }
  }

  /**
   * Make ajax to url from configuration passing data as attribute
   * @param  {Object} - params, json with additional parameters to url
   * ex.:{ zipCode: plz }
   */
  makeAjax(params) {
    console.warn(params);

    let _this = this,
      config = _this.config,
      $element = _this.$element;

    return PlaceService.getPlaces(params)
      .then(data => {
        //Removing loading icon
        $element.closest('.form__control').removeClass('loading');

        if (
          data.result?.places.length === 0 &&
          data.statusCode === 'OK' &&
          data.message !== ''
        ) {
          const formElem = $element[0].closest('.form__element');
          formElem.classList.add('validation--error');
          formElem.querySelector('.form__errorText').innerHTML = data.message;
        }

        if (_this.config.cache) {
          _this.saveToCache(data, $element.val());
        }
        //setRedirection based on result
        _this.showResults(data.result);
        _this._adjustCityPosition($element.val());
      })
      .catch(e => {
        //Removing loading icon
        $element.closest('.form__control').removeClass('loading');
        _this.Log.error('Typeahead Error from ajax call!');
        if (_this.Log.logLevel.value <= 2) {
          _this.Log.debug('Typeahead local fallback is about to start');
        }
      });
  }
  /**
   * save data to cache
   * @param  {Object} data - object with results from ajax results
   * @param  {String} key - name of key from session storage
   */
  saveToCache(data, key) {
    let _this = this;
    key = key + _this.$element.attr('name');
    try {
      sessionStorage.setItem(key, JSON.stringify(data));
    } catch (e) {
      _this.Log.error('Error during saving to sessionStorage:');
      _this.Log.error(e);
    }
    _this.Log.debug('Typeahead save to cach performed');
  }
  /* save data to cache
   * @param  {Object} data - object with results from ajax results
   * @param  {String} key - name of key from session storage
   */
  readFromCache(key) {
    var _this = this,
      key = key + _this.$element.attr('name'),
      obj = { ajax: true };
    try {
      obj = JSON.parse(sessionStorage.getItem(key));
    } catch (e) {
      _this.Log.error('Typeahead Error during reading from sessionStorage:');
      _this.Log.error(e);
    }
    // if no entry in sessionStorage then return is null
    // let's back to default in this case
    if (obj === null) {
      obj = { ajax: true };
    }
    _this.Log.debug('Typeahead from sessionStorage we got: ', obj);
    return obj;
  }
  /**
   * Read results from cache or by ajax from remote service based on configuration
   * @param  {Sting} - 5 digit PLZ but passed as string
   */
  getResults(query) {
    var $element = this.$element,
      _this = this;
    if (_this.config.cache) {
      _this.Log.log('Typeahead trying to read from cache..');
      var resultLocal = _this.readFromCache(query);
      if (resultLocal.ajax) {
        // unfortunately no cache
        _this.makeAjax(_this.urlQuery(query));
      } else {
        //_this.setRedirection(resultLocal);
        _this.showResults(resultLocal);
      }
      _adjustCityPosition(_this.$element.val());
    } else {
      _this.makeAjax(_this.urlQuery(query));
    }
  }

  /**
   * Display cityName in proper place whether there is data-injected-name in input field or not
   * @param  {String} - name of the city
   */
  showResultsPosition(cityName) {
    let _this = this;
    const injectedName = _this.$element.data('injected-name');

    if (injectedName) {
      const injectedField = document.getElementById(injectedName);

      if (injectedField) {
        injectedField.value = cityName || '';
      }
    } else {
      _this.$element.parents('.twitter-typeahead').attr('data-content', cityName || '');
    }
  }

  /**
   * set Redirection url on DOM attribute (data-typeahead-url) based on passed data object (usually from service)
   * @param  {Object} - response from backend service (or read from cache)
   */
  showResults(result) {
    let _this = this;
    // 2. If redirection done then as prio B grey city name
    // 2.1 Currently due to big time pressure we support only first city name
    if (result.places) {
      if (result.places.length <= 1) {
        let cityName =
          typeof result.places[0].name === 'undefined'
            ? result.places[0]
            : result.places[0].name;
        _this.showResultsPosition(cityName);
        _this.setStateValid();
        _this.selectResultPubSub(result, _this.$element.val() + ' ' + cityName);
      } else {
        // we got more results
        _this.Log.debug(
          'Typeahead more than one option detected... showing dropDown...'
        );
        _this.showDropdown(result);
        //$element.parents('.twitter-typeahead').attr('data-content', '');
      }
    } else {
      _this.setStateNotValid();
    }
    if (_this.$element.attr('data-typeahead-force-validation') === 'true') {
      vfde.utilities.validate(_this.$element[0]);
      _this.$element.attr('data-typeahead-force-validation', 'false');
    }
  }
  /**
   * selectResult - send pubSub signal just after selection from typeahead
   * (either manual from available list or automatically when one result only)
   * @param  {Object} - resultas response from backend service (or read from cache)
   * @param  {String} - pSelectedValue string value from field (in case of PLZ also cityname)
   */
  selectResultPubSub(result, pSelectedValue) {
    let _this = this,
      /** Array for street inputs from borderCase */
      $streetInputs = [];
    if (_this.selectedValue === pSelectedValue) {
      _this.Log.debug('Typeahead pubSub cancel as no result change!');
      return;
    }
    // clear address field as zip changed anyway
    // we need to clear address fields in case of following:
    // 22145 Stapfeld with filled in street switch PLZ to 12526
    // entered street name is incorrect
    // so let's clear them always on ZIP or CITY change
    // $streetInputs = $('.row .borderCaseContent input').not('[type="submit"]'); // [OLD VERSION]
    $streetInputs = this.$element
      .parents('.row')
      .next('.borderCaseContent')
      .find('input')
      .not('[type="submit"]'); // [MORE DETAILED VERSION]
    $streetInputs.each(function () {
      $(this).val('');
    });

    _this.selectedValue = pSelectedValue;
    if (_this.config.topic !== '') {
      vfde.libs.PubSub.publish(_this.config.topic, {
        me: _this.me,
        newValue: pSelectedValue,
        data: JSON.stringify(result)
      });
      _this.Log.debug('Typeahead pubSub signal topic:', _this.config.topic);
      _this.Log.debug('Typeahead pubSub signal newValue:', pSelectedValue);
      _this.Log.debug('Typeahead pubSub signal result:', result);
    }
  }
  /**
   * private method, used specially for old typeahead now rebuilds a bit
   * @param  {Array} - It should be array of strings
   * other type of typehead like streetname should overide this method to adjust how value is build!
   */
  myDataset(strings, q) {
    let _this = this;
    // q - search query, cb - callback this is needed for old typeahead
    var matches = [];
    for (let idx in strings) {
      let displayName =
        typeof strings[idx].name !== 'undefined' ? strings[idx].name : strings[idx];
      matches.push({
        displayName: displayName,
        value: q + ' ' + displayName,
        q: q
      });
    }
    _this.Log.debug('following matches found in dataset...', matches);
    return matches;
  }

  /**
   * show dropDown component when more than one option is possible to choose from backend service
   * @param  {Object} - object from which typeahead suggestions will be build can be ajax result or it's part
   */
  showDropdown(pDataset) {
    let _this = this;
    if (window.vfde.isMobile) {
      _this.$element.blur();
      // -50px because some browser can have url input present
      // maybe if we check old android and no find then we will decrease or remove it
      // so far in simulator url field from browser is present
      $('html, body').scrollTop(_this.$element.offset().top - 50);
    }
    _this.setStateNotChosen();
    _this.addGlobalHandler();
    _this.Log.debug('Typeahead trying to populate options open');
    _this.populateTypeahead(pDataset);
  }
  /**
   * populate dropDown component when more than one option is possible to choose from backend service
   * @param  {Object} - pDataset json result from location service
   */
  populateTypeahead(pDataset) {
    let _this = this,
      dataset = typeof pDataset.places === 'undefined' ? pDataset : pDataset.places,
      source = _this.myDataset(dataset, _this.$element.val());
    $('#' + _this.me).empty();
    for (let index in source) {
      let $li = $(
        "<li class='input__overlay_item--white'><a>" +
          source[index].q +
          '</a>' +
          source[index].displayName +
          '</li>'
      );
      $li.on('click', function () {
        _this.selectHandler(source[index], pDataset);
      });
      $('#' + _this.me).append($li);
    }
    // adjust width based on input as it is absolute element
    //$('#' + this.me).parent().width(_this.$element.width()+5);
    $('#' + this.me)
      .parent()
      .addClass('input__overlay--show');
  }
  /**
   * event attached when user click on one possible option from typeahead
   * this function call showMask to capture click also outside dropdown
   * we need to pass both all options and selected one as both are needed for some behaviour this method can trigger
   * @param  {Object} - pDataset json result from location service
   * @param  {Object} - selectedData single selected entry
   */
  selectHandler(selectedData, pDataset) {
    let _this = this;
    $('#' + _this.me).empty();
    $('#' + _this.me)
      .parent()
      .removeClass('input__overlay--show');
    _this.showResultsPosition(selectedData.displayName);
    _this.setStateValid();
    _this.Log.debug(
      'Typeahead:selected fired and tp destroyed, pubsub signal in about to send'
    );
    _this.selectResultPubSub(pDataset, selectedData.value);
  }
  /**
   * simple method to hide opened typeahead when user 'go away'
   */
  addGlobalHandler() {
    let _this = this;
    $('html').on('click', function () {
      $('.input__overlay--show').removeClass('input__overlay--show');
      $('html').unbind('click');
    });
  }
  /**
   * adjust cityName by translateX depending on number of 'ones' in zipCode
   */
  _adjustCityPosition(zipCode) {
    let _this = this;
    let numberOfOnes = 0;
    let regxp = zipCode.match(/1/g);

    if (regxp != null) {
      numberOfOnes = regxp.length;
      _this.$element.parent().removeClass(
        _this.$element
          .parent()
          .attr('class')
          .match(/twitter-typeahead--ones-\S+/g)
      );
      _this.$element.parent().addClass(`twitter-typeahead--ones-${numberOfOnes}`);
    } else {
      _this.$element.parent().removeClass(
        _this.$element
          .parent()
          .attr('class')
          .match(/twitter-typeahead--ones-\S+/g)
      );
    }
  }
}
