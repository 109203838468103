const headersCommon = {
  'Content-Type': 'application/json'
};

const davisHeaders = (davisConfig, tokenData) => ({
  ...headersCommon,
  'Ocp-Apim-Subscription-Key': davisConfig.subscriptionKey,
  'davis-token': tokenData.token
});

const getDefaultRequest = (davisConfig, tokenData, guid) => ({
  Client: davisConfig.client,
  Mandant: davisConfig.mandant,
  Timeout: null,
  StandVom: null,
  ParentLogId: tokenData.parentLogId,
  TransaktionsId: guid,
  Sprache: davisConfig.sprache
});

export { headersCommon, getDefaultRequest, davisHeaders };
