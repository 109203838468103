import $ from 'jquery';
import vfde from '../vfde';
import { PubSub } from 'pubsub-js';

export function toggleBorderCase(element, config) {
  const $element = $(element);
  const topic = config.topic;
  const autoSubmit = config.autoSubmit || false;
  let $adressElements;

  PubSub.subscribe(topic, function (msg, data) {
    if (data.data !== undefined) {
      //lookup if border case inputs are needed to get the right region
      let vfServiceLocationData = data.data;
      let place = '';
      if (vfServiceLocationData !== '') {
        vfServiceLocationData = JSON.parse(vfServiceLocationData);

        if (vfServiceLocationData.locationWorkflowStatus === 'LOCATION_VALID') {
          //no border case needed
          $element
            .parents()
            .find('.calc-elements')
            .removeClass('col-md-12')
            .addClass('col-md-9');
          $('.col-tabletDown').show();
          $('.divider--calculator').show();
          $('.border-case__element').hide();
          $('.border-case__element--inverse').show();
          $element.hide();
          $adressElements = $element.find('.form__input');
          $adressElements.each(function (index) {
            $(this).val('');
          });
          if (autoSubmit === true) {
            $element.parents('form').submit();
          }
        } else {
          // we need to the check the selected place

          for (place in vfServiceLocationData.places) {
            if (
              data.newValue ===
              vfServiceLocationData.places[place].zipCode +
                ' ' +
                vfServiceLocationData.places[place].name
            ) {
              if (vfServiceLocationData.places[place].isBordercase === true) {
                $element.show();
                $('.col-tabletDown').hide();
                $('.divider--calculator').hide();
                $('.border-case__element').show();
                $('.border-case__element--inverse').hide();
                $element
                  .parents()
                  .find('.calc-elements')
                  .removeClass('col-md-9')
                  .addClass('col-md-12');
              } else {
                $element
                  .parents()
                  .find('.calc-elements')
                  .removeClass('col-md-12')
                  .addClass('col-md-9');
                $('.col-tabletDown').show();
                $('.divider--calculator').show();
                $('.border-case__element').hide();
                $('.border-case__element--inverse').show();
                $element.hide();
                if (autoSubmit === true) {
                  $element.parents('form').trigger('submit');
                }
              }
            }
          }
        }
      }
    }
  });
}

export default toggleBorderCase;

vfde.behaviours.register(toggleBorderCase, 'tariff.toggleBorderCase');
