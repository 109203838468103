import $ from 'jquery';

import { Log } from '../utilities/Logger';
import { TypeaheadPlace } from '../behaviours/Typeahead_place';
import { TypeaheadStreet } from '../behaviours/Typeahead_street';
import { TypeaheadCustom } from '../behaviours/Typeahead_custom';

const CONFIG_ATTRIBUTE = 'data-typeahead-config';

export function typeahead() {
  const $typeaheads = $('[data-js-controller="typeahead"]');

  $.each($typeaheads, (i, el) => {
    const $this = $(el);
    const attribute = $this.attr(CONFIG_ATTRIBUTE);
    let objConfig = {};

    try {
      objConfig = JSON.parse(attribute);

      // TODO: get rid of the idea of passing Log to the dependencies!
      // FIXME: import Log directly in those modules
      switch (objConfig.type) {
        case 'places':
          new TypeaheadPlace($this, Log, objConfig);
          break;
        case 'street':
          new TypeaheadStreet($this, Log, objConfig);
          break;
        case 'custom':
          new TypeaheadCustom($this, Log, objConfig);
          break;
        default:
          new TypeaheadPlace($this, Log, objConfig);
      }
    } catch (error) {
      Log.error('Typeahead config read error:', attribute);
      Log.error(error);
    }
  });
}
