import $ from 'jquery';

import { navigator } from '../globals';

/** {Boolean} -  return true if detected IE */
export const isEdge = () => {
  const _isEdge =
    navigator.appName == 'Microsoft Internet Explorer' ||
    !!navigator.userAgent.match(/Edge/);

  $('html').addClass(`${_isEdge ? '' : 'no-'}Edge`);

  return _isEdge;
};

export default isEdge();
