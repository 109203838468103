import { document } from '../globals';

// add global utility to handle cookies as it is more and more needed
export const cookies = {
  // Based On QA: see more http://lea.verou.me/2009/12/reading-cookies-the-regular-expression-way/
  calculateExtDays(exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    return 'expires=' + d.toUTCString();
  },

  get(name = '') {
    name = name.replace(/([.*+?^=!:${}()|[\]\/\\])/g, '\\$1');

    const regex = new RegExp('(?:^|;)\\s?' + name + '=(.*?)(?:;|$)', 'i');
    const match = document.cookie.match(regex);

    return match && decodeURI(match[1]); // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/unescape
  },

  set(cname, cvalue, cexdays) {
    if (typeof cvalue === 'undefined' || typeof cname === 'undefined') {
      return;
    }
    const sep = '; ';
    const data = [cname, '=', encodeURI(cvalue), sep];

    if (!!cexdays && !isNaN(cexdays)) {
      const expires = `expires=${cookies.calculateExtDays(cexdays)}`;
      data.push(expires, sep);
    }
    data.push('path=/', sep);
    if (location.protocol === 'https:') {
      data.push('Secure');
    }
    document.cookie = data.join('');
  },

  setWithDomain(cname, cvalue, cexdays, cdomain) {
    const sep = '; ';
    const data = [cname, '=', encodeURI(cvalue), sep];

    if (!!cexdays && !isNaN(cexdays)) {
      const expires = `expires=${cookies.calculateExtDays(cexdays)}`;
      data.push(expires, sep);
    }
    if (!!cdomain) {
      const domain = `path=/; domain=${cdomain}`;
      data.push(domain);
    }
    document.cookie = data.join('');
  },

  remove(cname) {
    document.cookie = cname + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/';
  }
};

export default cookies;
