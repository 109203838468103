import { getTokenAnonym } from '../services/DI/account';
import { headersCommon } from './config';

let tokenData = {};
let headers = {};

const prepareHeaders = async () => {
  tokenData = await getTokenAnonym();
  headers = {
    ...headersCommon,
    'client-anonym-davis-token': tokenData.token,
    'client-anonym-token-parent-log-id': tokenData.parentLogId
  };

  return { headers, tokenData };
};

export { prepareHeaders };
