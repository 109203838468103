import $ from 'jquery';
import vfde from '../vfde';
import { Log } from '../utilities/Logger';
import { window } from '../globals';

// Find all YouTube videos
export function youtube(scale = 1) {
  const $youtubeVideos = $('[data-youtube-video]');

  if ($youtubeVideos.length > 0) {
    // now as we detect that there are YT videos then attach event on resize
    $(window)
      .resize(() => {
        Log.log('YT video resize fired!');

        // Resize all videos according to their own aspect ratio
        $youtubeVideos.each((i, el) => {
          const $el = $(el);
          const newHeight = parseInt($el.parent().css('padding-bottom'), 10) * scale; // Yes! We use 'padding-bottom' not 'height'!!!
          const newWidth = $el.parent().width() * scale;

          $el.width(newWidth).height(newHeight);

          Log.log(
            'YT video just resized with width/height/scale: ',
            newWidth,
            newHeight,
            scale
          );
        });
      })
      .resize(); // Kick off one resize to fix all videos on page load
  }
}

vfde.utilities.registerUtility(youtube, 'youtube');
