import { Log } from '../utilities/Logger';

import { getGUID } from '../utilities/guid';
import { isRequired, TYPES } from '../utils/types';

const SELECTOR = {
  MAIN: '.content-slideshow--slides > li',
  MOBILEBUTTONS: '.content-slideshow--mobile-paging a'
};
const DEFAULTS = {
  autoSlide: true,
  autoSlideAfterChange: false,
  slideTimeInSec: 5
};

export class Container {
  /**
   * Constructor
   * @param {Object} container - dom object in which container slide show is located
   */
  constructor(container = isRequired(TYPES.ELEM)) {
    this.container = container;
    this.id = getGUID();
    this.itemsArray = this.container.querySelectorAll(SELECTOR.MAIN);
    this.itemsLen = this.itemsArray.length;
    this.currentIndex = 0;

    let dataConfigObject = null;

    try {
      dataConfigObject = JSON.parse(this.container.dataset.slideshowConfig);
    } catch (e) {
      dataConfigObject = {};
      Log.error('Error parsing ContainerSlideShow with config');
    }

    this.config = Object.assign({}, DEFAULTS, dataConfigObject);

    const prevButton = this.container.querySelector('.prevSlideButton');
    const nextButton = this.container.querySelector('.nextSlideButton');

    prevButton.addEventListener('click', () => this.manualSlide('prev'));
    nextButton.addEventListener('click', () => this.manualSlide('next'));

    this.slideInterval = null;

    if (this.config.autoSlide) {
      this.runAutoSlide();
    }

    this.mobileButtons = this.container.querySelectorAll(SELECTOR.MOBILEBUTTONS);

    this.mobileButtons.forEach((button, i) => {
      button.addEventListener('click', () => this.manualSlide('skip', i));
    });

    // touch swipe handler
    // http://stackoverflow.com/questions/2264072/detect-a-finger-swipe-through-javascript-on-the-iphone-and-android
    this.touchStats = {
      xDown: null,
      yDown: null
    };
    this.container.addEventListener(
      'touchstart',
      evnt => this.handleTouchStart(evnt),
      false
    );
    this.container.addEventListener(
      'touchmove',
      evnt => this.handleTouchMove(evnt),
      false
    );

    Log.info('ContainerSlideShow constructor finished');
  }

  /**
   * Perform sliding of quotes insider Container, change index and based on it add/remove css class 'active''
   * @param {string} slideType - just string to either increase/decrease index or skip like goto to specific index
   * @param {number} slideIndex - index to which we should go in case of skip
   */
  slide(slideType, slideIndex) {
    const slideTypeHandler = {
      prev: () => {
        this.currentIndex--;
        if (this.currentIndex < 0) {
          this.currentIndex = this.itemsLen - 1;
        }
      },
      next: () => {
        this.currentIndex++;
        if (this.currentIndex > this.itemsLen - 1) {
          this.currentIndex = 0;
        }
      },
      skip: slideIndex => {
        this.currentIndex = slideIndex;
      }
    };

    const cycleItems = () => {
      let activeElements = this.container.getElementsByClassName('active');

      // slide element
      activeElements[0].className = '';
      this.itemsArray[this.currentIndex].className = 'active';

      // mobile dots element
      activeElements[1].className = activeElements[1].className.replace(
        /(?:^|\s)active(?!\S)/,
        ''
      );
      this.mobileButtons[this.currentIndex].className += ' active';
    };

    slideTypeHandler[slideType](slideIndex);
    cycleItems();
  }

  /**
   * Normal slide event, with also disabling autoSlide functionality if is enabled and flag autoSlideAfterChange set to false
   * @param {string} slideType - just string to either increase/decrease index or skip like goto to specific index
   * @param {number} slideIndex - index to which we should go in case of skip
   */
  manualSlide(slideType, slideIndex) {
    clearInterval(this.slideInterval);
    this.slideInterval = null;

    this.slide(slideType, slideIndex);

    if (this.config.autoSlideAfterChange) {
      this.runAutoSlide();
    }
  }

  /**
   * Enable autoSlide funcionality
   */
  runAutoSlide() {
    this.slideInterval = setInterval(() => {
      this.slide('next');
    }, this.config.slideTimeInSec * 1000);
  }

  // http://stackoverflow.com/questions/2264072/detect-a-finger-swipe-through-javascript-on-the-iphone-and-android
  handleTouchStart(evt) {
    this.touchStats.xDown = evt.touches[0].clientX;
    this.touchStats.yDown = evt.touches[0].clientY;
  }

  handleTouchMove(evt) {
    if (!this.touchStats.xDown || !this.touchStats.yDown) {
      return;
    }

    const xUp = evt.touches[0].clientX;
    const yUp = evt.touches[0].clientY;

    const xDiff = this.touchStats.xDown - xUp;
    const yDiff = this.touchStats.yDown - yUp;

    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      /*most significant*/
      if (xDiff > 0) {
        /* left swipe */
        this.manualSlide('next');
      } else {
        /* right swipe */
        this.manualSlide('prev');
      }
    }

    /* reset values */
    this.touchStats.xDown = null;
    this.touchStats.yDown = null;
  }
}

export default Container;
