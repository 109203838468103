import $ from 'jquery';
import vfde from '../vfde';
import { window } from '../globals';
import { Log } from './Logger';

/**
 * sanitize function used for removing rubbish from input without error message
 * @param  {string} element selector
 * @return {Boolean} return true if sanitize passed
 */
export const sanitize = function (element) {
  /** {jQuery object} $element - jQuery object based on selector */
  const $element = $(element);

  /** {JSON} config - read config from data-validate attribute */
  let config = {};
  function getSelectionText() {
    if (window.getSelection && document.activeElement) {
      let userSelection = '';
      try {
        if (
          document.activeElement.nodeName === 'TEXTAREA' ||
          document.activeElement.nodeName === 'INPUT'
        ) {
          const ta = document.activeElement;
          userSelection = ta.value.substring(ta.selectionStart, ta.selectionEnd);
        } else {
          userSelection = window.getSelection().toString();
        }
        return userSelection;
      } catch (e) {
        // evaluation for number
        return '';
      }
    }
  }
  /** 0 - special keys F1...F12,Tab...; 8 - Backspace, 13 - Enter*/
  const allowedKeys = { 0: 'specialKeys', 8: 'Backspace', 13: 'Enter' };

  if ($element.length) {
    // try to parse JSON
    try {
      config = JSON.parse($element.attr('data-sanitize'));
    } catch (error) {
      // console.warn('Error parsing data-sanitize');
      //if config empty then no sanitazation!
      Log.error('sanitize.js wrong json provided:', error);
      Log.error($element[0], $element.attr('data-sanitize'));
      return;
    }

    /** {String} regexStr - String with custom regular expression to validate if exists */
    const regexStr = new RegExp(config.rules.allowedRegex.regex);
    /** {Number} max - Maximal number of characters to sanitize */
    let max = 5;

    if (typeof config.rules.maxlength !== 'undefined') max = config.rules.maxlength.max;

    $element.on('keypress paste', function (event) {
      let temp = $element.val(),
        input = String.fromCharCode(event.which),
        inputValid = true;

      if (event.type === 'paste') {
        temp = '';
        try {
          input = event.originalEvent.clipboardData.getData('Text');
        } catch (e) {
          input = window.clipboardData.getData('Text');
        }
      }

      // is the number an valid input?
      if (!regexStr.test(input)) {
        inputValid = false;
      }

      // will it be out of range?
      // 22145 if digit '2' will be selected and 3 will be typed strange result ...
      // so we need to remove selected digit(s) and then compare
      if (
        $element.val().replace(getSelectionText().toString(), '').length +
          input.length >
        max
      ) {
        inputValid = false;
      }

      // input is not Valid we have to clean it up
      if (!inputValid) {
        // ctrlKey false needed to allow CTRL-A and CTRL-V on Gecko(Firefox) browsers
        if (!(event.which in allowedKeys) && event.ctrlKey === false) {
          event.preventDefault();
        }
        // Still we need to block paste if input was invalid
        if (event.type === 'paste') {
          event.preventDefault();
        }

        if (event.ctrlKey === false) {
          temp = temp.replace(getSelectionText().toString(), '');
          temp = temp + input;

          const useComma = config.rules.allowedRegex.useComma;

          if (config.rules.allowedRegex.clearInput === 'false') {
            temp = temp.substring(0, temp.length - 1);
          } else {
            temp = temp.replace(useComma ? /(?!,)\D/g : /\D/g, '');
          }

          if (temp.length > max) {
            temp = temp.substring(0, max);
          }

          $element.val(temp);
        }
        // 2. Typeahead trigger in case of paste
        // AG: Old code I will keep it here if we encountered problem with behaviour and sanitization together
        // Seems no issue during testing on 28.04.2015
        //$element.change();
        //$element.trigger('input');
        $element.change();
        $element.trigger('input');
      }
    });
  }
};

export default sanitize;

vfde.utilities.registerUtility(sanitize, 'sanitize');
