export default {
  setQueryParams(params) {
    if (params) {
      const urlParams = new URLSearchParams();

      for (const [key, value] of Object.entries(params)) {
        if (value && value.length) {
          urlParams.append(key, value);
        }
      }

      window.history.replaceState(
        {},
        '',
        decodeURIComponent(`${window.location.pathname}?${urlParams}`)
      );
    }
  },
  createCustomUrlQueryParams(url, params) {
    if (params && url) {
      const urlParams = new URLSearchParams();

      for (const [key, value] of Object.entries(params)) {
        if (value.length) {
          urlParams.append(key, value);
        }
      }

      return decodeURIComponent(`${url}?${urlParams}`);
    }

    return null;
  },
  getQueryParams() {
    const urlParams = new URLSearchParams(window.location.search);
    let params = {};

    for (let p of urlParams) {
      let key = p[0];
      let value = p[1];
      params = { ...params, [key]: value };
    }

    return Object.keys(params).length === 0 ? null : params;
  }
};
