var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"welcomeText") || (depth0 != null ? lookupProperty(depth0,"welcomeText") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"welcomeText","hash":{},"data":data,"loc":{"start":{"line":13,"column":16},"end":{"line":13,"column":33}}}) : helper))) != null ? stack1 : "")
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "                <li>"
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "</li>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  \"buttonColor\": \""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"buttonColor") || (depth0 != null ? lookupProperty(depth0,"buttonColor") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"buttonColor","hash":{},"data":data,"loc":{"start":{"line":32,"column":34},"end":{"line":32,"column":49}}}) : helper)))
    + "\",\n";
},"7":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  \"buttonText\": \""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"buttonText") || (depth0 != null ? lookupProperty(depth0,"buttonText") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"buttonText","hash":{},"data":data,"loc":{"start":{"line":35,"column":33},"end":{"line":35,"column":47}}}) : helper)))
    + "\",\n";
},"9":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  \"placeholder\": \""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"placeholder") || (depth0 != null ? lookupProperty(depth0,"placeholder") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"placeholder","hash":{},"data":data,"loc":{"start":{"line":38,"column":34},"end":{"line":38,"column":49}}}) : helper)))
    + "\",\n";
},"11":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  \"serverErrorMessage\": \""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"serverErrorMessage") || (depth0 != null ? lookupProperty(depth0,"serverErrorMessage") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"serverErrorMessage","hash":{},"data":data,"loc":{"start":{"line":41,"column":41},"end":{"line":41,"column":63}}}) : helper)))
    + "\",\n";
},"13":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  \"registeredMessage\": \""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"registeredMessage") || (depth0 != null ? lookupProperty(depth0,"registeredMessage") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"registeredMessage","hash":{},"data":data,"loc":{"start":{"line":44,"column":40},"end":{"line":44,"column":61}}}) : helper)))
    + "\",\n";
},"15":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  \"validationErrorMessage\": \""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"validationErrorMessage") || (depth0 != null ? lookupProperty(depth0,"validationErrorMessage") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"validationErrorMessage","hash":{},"data":data,"loc":{"start":{"line":47,"column":45},"end":{"line":47,"column":71}}}) : helper)))
    + "\"\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal fade\" id=\"modalLoginBox\" tabindex=\"-1\" role=\"dialog\" aria-labelledby=\"myModalLabel\">\n  <div class=\"modal-dialog modal-md\" role=\"document\">\n    <a class=\"button--svg close color--grey-fallback\" data-dismiss=\"modal\" aria-label=\"Close\">\n      <i class=\"icon vf-icon vf-icon-close\"></i>\n    </a>\n    <div class=\"modal-content color-bg--grey-fallback\">\n      <!-- modal content -->\n        <div class=\"row loginbox\">\n          <div class=\"col-sm-10 col-sm-offset-1 pl24 pr24 pt24\">\n            <h1 class=\"\">Gutscheincode erhalten</h1>\n            <p class=\"form__text\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"welcomeText") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":14},"end":{"line":14,"column":21}}})) != null ? stack1 : "")
    + "            </p>\n            <ol>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"list-options") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":14},"end":{"line":19,"column":23}}})) != null ? stack1 : "")
    + "            </ol>\n\n            <div data-reactive='{\n              \"vue.runApp\": {\n                \"name\": \"Optin\",\n                \"props\": {\n                  \"endpointUrl\": \""
    + alias4(((helper = (helper = lookupProperty(helpers,"endpointUrl") || (depth0 != null ? lookupProperty(depth0,"endpointUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"endpointUrl","hash":{},"data":data,"loc":{"start":{"line":26,"column":34},"end":{"line":26,"column":49}}}) : helper)))
    + "\",\n                  \"method\": \""
    + alias4(((helper = (helper = lookupProperty(helpers,"method") || (depth0 != null ? lookupProperty(depth0,"method") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"method","hash":{},"data":data,"loc":{"start":{"line":27,"column":29},"end":{"line":27,"column":39}}}) : helper)))
    + "\",\n                  \"voucherId\": \""
    + alias4(((helper = (helper = lookupProperty(helpers,"voucherId") || (depth0 != null ? lookupProperty(depth0,"voucherId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"voucherId","hash":{},"data":data,"loc":{"start":{"line":28,"column":32},"end":{"line":28,"column":45}}}) : helper)))
    + "\",\n                  \"gdprText\": \""
    + alias4(((helper = (helper = lookupProperty(helpers,"gdprText") || (depth0 != null ? lookupProperty(depth0,"gdprText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"gdprText","hash":{},"data":data,"loc":{"start":{"line":29,"column":31},"end":{"line":29,"column":43}}}) : helper)))
    + "\",\n                  \"gdprError\": \""
    + alias4(((helper = (helper = lookupProperty(helpers,"gdprError") || (depth0 != null ? lookupProperty(depth0,"gdprError") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"gdprError","hash":{},"data":data,"loc":{"start":{"line":30,"column":32},"end":{"line":30,"column":45}}}) : helper)))
    + "\",\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"buttonColor") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":18},"end":{"line":33,"column":25}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"buttonText") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":18},"end":{"line":36,"column":25}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"placeholder") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":18},"end":{"line":39,"column":25}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"serverErrorMessage") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":18},"end":{"line":42,"column":25}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"registeredMessage") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":43,"column":18},"end":{"line":45,"column":25}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"validationErrorMessage") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":46,"column":18},"end":{"line":48,"column":25}}})) != null ? stack1 : "")
    + "                }\n              }\n            }'>\n            </div>\n\n            <div class=\"text--notes mt30\">\n              <p class=\"mb30\">\n                "
    + alias4(((helper = (helper = lookupProperty(helpers,"text-notes") || (depth0 != null ? lookupProperty(depth0,"text-notes") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"text-notes","hash":{},"data":data,"loc":{"start":{"line":56,"column":16},"end":{"line":56,"column":30}}}) : helper)))
    + "\n              </p>\n            </div>\n          </div>\n        </div>\n      <!-- /modal content -->\n    </div>\n  </div>\n</div>\n";
},"useData":true});