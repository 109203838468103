(function (vfde) {
  'use strict';

  /**
   * format function used for formating tariff value
   * @param  {[type]} n
   * @return {[type]} formated value
   */
  var iButton = function (selector) {
    $(selector).each(function () {
      var $iButton__icon = $(this);
      $iButton__icon.qtip({
        prerender: true,
        content: {
          // Use the "div" element next to this for the content
          text: $iButton__icon.next('div'),
          button: true
        },
        show: {
          event: 'click'
        },
        hide: {
          event: 'unfocus'
        },
        style: {
          classes: 'iButton__tooltip'
        },
        events: {
          show: function (event, api) {
            api.reposition(event); // call is necessary because, the initial position mechanism doesn't calculate correctly in case of an flip adjustment
          }
        },
        position: {
          adjust: {
            x: 12,
            method: 'shift flip'
          },
          viewport: true,
          target: $iButton__icon.parents('.iButton'),
          my: 'top left', // Position my top left...
          at: 'bottom right' // at the bottom right of...
        }
      });
    });
  };

  vfde.utilities.registerUtility(iButton, 'iButton');
})(window.vfde);
