const maskingPatterns = {
  '#': /\d/,
  A: /[a-z]/i,
  N: /[a-z0-9]/i,
  X: /./
};

const checkInputMask = elements => {
  elements.forEach(element => {
    const maskData = element.getAttribute('data-mask') || '{}';
    const config = JSON.parse(maskData);
    const originalMask = config.mask;
    const isDynamicMask = config.dynamic;
    const extensions = config.extensions;
    let mask = originalMask;

    if (mask) {
      element.addEventListener('input', event => {
        const value = element.value || '';
        let indexMask = 0;
        let indexValue = 0;
        let output = '';
        let original = [originalMask];

        while (indexMask < mask.length && indexValue < value.length) {
          const characterInput = value[indexValue];
          const extensionData = extensions.find(ext => ext[indexMask]);

          if (!original[indexMask]) {
            original[indexMask] = mask;
          }

          if (mask !== original[indexMask]) {
            mask = original[indexMask] || original[0];
          } else if (isDynamicMask && extensionData) {
            const regexNewCharacter = maskingPatterns[extensionData[indexMask]];

            if (
              (regexNewCharacter && regexNewCharacter.test(characterInput)) ||
              characterInput === extensionData[indexMask]
            ) {
              mask = `${original[indexMask - 1].slice(0, indexMask)}${
                extensionData[indexMask]
              }${original[indexMask - 1].slice(indexMask)}`;

              original[indexMask] = mask;
            } else {
              mask = original[indexMask - 1];
            }
          }

          const characterMask = mask[indexMask];
          const regex = maskingPatterns[characterMask];

          if (regex) {
            if (regex.test(characterInput)) {
              output += characterInput;
              indexMask++;
            }
            indexValue++;
          } else {
            output += characterMask;
            if (characterInput === characterMask) indexValue++; // user typed the same char
            indexMask++;
          }
        }
        element.value = output;
      });
    }
  });
};

export { checkInputMask };
