// fields names to map from
const fields = {
  statusCode: 'StatusCode',
  messages: 'Meldungen',
  message: {
    description: 'FachlicheBeschreibung'
  },
  addresses: 'Adressen',
  address: {
    zipCode: 'PLZ',
    place: 'Ort',
    street: 'Strasse',
    houseNo: 'Hausnummer',
    district: 'Ortsteil'
  }
};

export { fields };
