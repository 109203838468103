import vfde from '../vfde';
import toggleDisabled from './toggleDisabled';
import toggleLoadingIndicator from './toggleLoadingIndicator';
import isVisible from './isVisible';
import trackFormSubmit from './formSubmitTracking';

export const formValidate = elements => {
  elements.forEach(element => {
    const form = element.closest('form');

    // in gtk_form, inputs requiring a minimum value need to validate on blur (this would be nicer in a configuration file, maybe the xml?)
    const minValInputs = form.querySelectorAll('input[data-validate*="minval"]');

    minValInputs.forEach(input => {
      input.addEventListener('blur', event => {
        vfde.utilities.validate(input);
      });
    });

    element.addEventListener('click', event => {
      if (form.length > 0) {
        /** {Boolean} passed - store validation result */
        let passed = true;
        /** {jQuery array} passed - store validation result */
        let elementsToValidate = form.querySelectorAll(
          '.form__input, .form__text-area, .form__radio-input'
        );
        let productType,
          place = '',
          zip = '',
          city = '',
          consumption = '';
        const scrollToIfInvalid = [];

        // to disable scrolling add in data-validate attribute (in form template): "disableScrollIfInvalid": true
        // by default every field is used to scroll to them if validation failed
        elementsToValidate.forEach(validatingElement => {
          if (isVisible(validatingElement)) {
            const test = vfde.utilities.validate(validatingElement);
            passed = passed && test.passed;

            if (!test.passed && !test.noScroll) {
              scrollToIfInvalid.push(validatingElement.id);
            }
          }
        });

        // scroll to first validation error only
        if (scrollToIfInvalid.length && scrollToIfInvalid[0] !== '') {
          // if no id at element then '' > skip it for scrolling
          document.getElementById(scrollToIfInvalid[0]).scrollIntoView({
            block: 'nearest',
            behavior: 'smooth'
          });
        }

        Log.debug('formValidate--> validation finished passed equals: ' + passed);
        if (passed) {
          try {
            const arr = form.getElementsByTagName('input');
            let i = 0;

            Log.debug('formValidate--> Array of input fields : ' + arr);
            // find input place and zip code to udpate them after for loop
            for (i = 0; i < arr.length; i++) {
              if (arr[i].getAttribute('name') === 'place') {
                place = arr[i];
              }
              if (arr[i].getAttribute('name') === 'zipCode') {
                zip = arr[i];
              }
              if (arr[i].getAttribute('name') === 'cityName') {
                city = arr[i];
              }
              if (
                arr[i].id === 'input__usage--gas' ||
                arr[i].id === 'input__usage--electricity'
              ) {
                consumption = arr[i];
                productType = arr[i].id === 'input__usage--gas' ? 'gas' : 'strom';
              }
            }

            vfde.cookies.set('cityName', city.value);
            vfde.cookies.set('zipCode', zip.value);
            vfde.cookies.set(consumption.name, consumption.value);
            vfde.cookies.set('sendFromHPCalculator', true);

            // form as default will remember previous input so we need to clear it
            //zip.value = (zip.value == '') ? place.value : zip.value;
            if (zip.value !== place.value) {
              Log.debug(
                'formValidate--> zip value is different than place to adjustment are happening'
              );
              zip.value = place.value;
              city.value = '';
            }
          } catch (e) {
            Log.debug(e);
          }

          //AG it seems that submit is not fired when submit initiated programatically
          // https://stackoverflow.com/questions/18774821/submit-event-does-not-fire-if-submit-initiated-programatically

          // if data-formFieldConvert set to true then remove . from inputs

          if (element.getAttribute('data-field-convert')) {
            elementsToValidate.forEach(element => {
              const search = '.';
              const replaceWith = '';
              element.value = element.value.split(search).join(replaceWith);
            });
          }

          if (element.getAttribute('data-indicator')) {
            toggleDisabled(element);
            toggleLoadingIndicator(element);
          }

          const trackingEvent = element.getAttribute('data-submit-tracking');

          if (trackingEvent) {
            const trackingData = {
              zipcode: zip.value,
              product: productType,
              consumptionH: consumption ? consumption.value : null,
              consumptionC: null
            };
            trackFormSubmit(trackingEvent, trackingData);
          }

          form.submit();

          Log.debug('formValidate--> Form submit triggered!');
        } else {
          event.preventDefault();
        }
      }
    });
  });
};

vfde.utilities.registerUtility(formValidate, 'formValidate');
export default formValidate;
