import $ from 'jquery';

import vfde from '../vfde';
import polyfills from './polyfills';
import { bfCacheLoad } from './bfCacheLoad';
import { formInputs, svgFix } from './fixes';
import { protectForms } from './protect-forms';
import { youtube } from './youtube';
import { showOnScroll } from './showOnScroll';
import { countLines } from './count-lines';
import { loginButtons } from './loginButtons';
import { maps } from './maps';
import { typeahead } from './typeahead';
import { contentSlideShow } from './contentSlideShow';
import { initHubTiles } from './hubTiles';
import { readTracking } from '../utilities/readTracking';
import { promotionTracking } from '../utilities/promotionTracking';
import { resizeAndLazyLoadStencilElements } from './stencil';
import { setGlobalClasses } from './setGlobalClasses';

import controllers from '../controllers';

import { initTokenAnonym } from '../vueCommon/services/DI/account';
import { healthCheck } from '../vueCommon/services/DI/healthCheck';
import { initCampaignTracking } from '../utilities/campaignTracking';
import { addParamsFromUrlToIframe } from './iframe';

export function init() {
  $(() => {
    healthCheck();
    initTokenAnonym();
    addParamsFromUrlToIframe();
    // polyfills
    polyfills();

    // fixes
    bfCacheLoad();
    formInputs();
    svgFix();
    promotionTracking();
    // global inits
    controllers.init(); // register components if needed
    readTracking();
    vfde.init();
    protectForms();
    youtube();
    showOnScroll();
    countLines();
    loginButtons();
    maps();
    typeahead();
    initHubTiles();
    contentSlideShow();
    initCampaignTracking();
    resizeAndLazyLoadStencilElements();
    setGlobalClasses();
  });
}
