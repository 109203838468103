import { TypeaheadPlace } from './Typeahead_place';

/**
 * Typeahead provide autocomplete functionality in input fields
 * @class Typeahead_street
 */
export class TypeaheadStreet extends TypeaheadPlace {
  constructor($element, log, objConfig) {
    super($element, log, objConfig);
    // overwrite cache setting as caching streetnames is more complex
    this.config.cache = false;
  }
  showResults(result) {
    let _this = this;
    if (result.addresses && result.addresses.length >= 1) {
      _this.showDropdown(result);
    } else {
      _this.setStateNotValid();
    }
  }
  populateTypeahead(pDataset) {
    let _this = this,
      dataset =
        typeof pDataset.addresses === 'undefined' ? pDataset : pDataset.addresses,
      source = _this.myDataset(dataset, _this.$element.val());
    $('#' + _this.me).empty();
    for (let index in source) {
      let $li = $(
        "<li class='input__overlay_item--white'><a>" +
          source[index].displayName +
          '</a></li>'
      );
      $li.on('click', function () {
        _this.selectHandler(source[index], pDataset);
      });
      $('#' + _this.me).append($li);
    }
    $('#' + this.me)
      .parent()
      .addClass('input__overlay--show');
  }
  myDataset(addresses, q) {
    let _this = this;
    // q - search query, cb - callback this is needed for old typeahead
    var matches = [];

    for (let idx in addresses) {
      let displayName =
        typeof addresses[idx].street !== 'undefined'
          ? addresses[idx].street
          : addresses[idx];
      matches.push({
        displayName: displayName,
        value: displayName,
        q: q
      });
    }
    _this.Log.debug('following matches found in dataset...', matches);
    return matches;
  }
  selectHandler(selectedData, pDataset) {
    let _this = this;
    $('#' + _this.me).empty();
    $('#' + _this.me)
      .parent()
      .removeClass('input__overlay--show');
    _this.$element.val(selectedData.value);
    _this.setStateValid();
    _this.Log.debug('Typeahead:selected fired and tp destroyed');
  }

  readValueFromFieldWithId(fieldId) {
    let allFiledsWithId = $('#' + fieldId);
    if (allFiledsWithId.length > 0) {
      return allFiledsWithId.eq(0).val();
    }
    return ''; // no value assigned
  }

  urlQuery(query) {
    let _this = this;
    let zipCode = _this.readValueFromFieldWithId('input_zipCode');
    let cityName = _this.readValueFromFieldWithId('input_cityName');
    return { zipCode: zipCode, place: cityName, street: query };
  }
}
