const componentsToLoadConditionally = [
  {
    selectors: ['product-direct-order'],
    handler: 'vueProductsNotCom',
    hippoHandler: 'initVueNonCom'
  },
  {
    selectors: [
      'products',
      'products-calculator',
      'products-calculator-emobility',
      'lp-products'
    ],
    handler: 'vueProducts',
    hippoHandler: 'initVueProducts'
  },
  {
    selectors: ['empty', 'optin'],
    handler: 'vueComponents',
    hippoHandler: 'initVueDefaultComponents'
  },
  {
    selectors: ['callback', 'sfaq'],
    handler: 'vueCallback',
    hippoHandler: 'initVueCallback'
  },
  {
    selectors: ['globalfaq'],
    handler: 'vueGlobalFAQ',
    hippoHandler: 'initVueGlobalFAQ'
  },
  { selectors: ['tinkshop'], handler: 'vueTinkShop', hippoHandler: 'initVueTinkShop' },
  {
    selectors: [
      'price-limit-calculators',
      'price-limit-calculator' // for snippet includer > finally will be removed
    ],
    handler: 'vuePriceLimitCalculator',
    hippoHandler: 'initVuePriceLimitCalculators'
  },
  {
    selectors: ['opt-in'],
    handler: 'vueOptIn',
    hippoHandler: 'initVueOptIn'
  },
  {
    selectors: ['spot-price-chart'],
    handler: 'vueSpotPriceChart',
    hippoHandler: 'initVueSpotPriceChart'
  },
  {
    selectors: ['flex-swiper'],
    handler: 'vueFlexSwiper',
    hippoHandler: 'initVueFlexSwiper'
  }
];

const loadVueComponent = (component, promises) => {
  const existingSelectors = document.querySelectorAll(component.selectors.join(','));

  if (existingSelectors.length) {
    promises.push(import(`./${component.handler}`));
  }
};

export { componentsToLoadConditionally, loadVueComponent };
