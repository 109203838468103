import { controllersToLoad } from '../controllersLoader';

export default {
  init() {
    const promises = [];

    controllersToLoad.forEach(controller => {
      const existingSelectors = document.querySelectorAll(
        controller.selectors.join(',')
      );

      if (existingSelectors.length) {
        promises.push(import(`./${controller.handler}`));
      }
    });

    // todo: check if you can load it dynamically, now load always
    promises.push(import('./lotteryController'));

    Promise.all(Array.from(new Set(promises))).then(p => {
      p.forEach(controller => controller.default.init());
    });
  }
};
