import { vfde } from '../vfde';
import { Log } from './Logger';

import { document } from '../globals';

/**
 * format function used for formating tariff value
 * @param  {[type]} n
 * @return {[type]} formated value
 */
export function showOnScroll($element) {
  // Internal helper to make scroll on click
  // scroll on click or other trigger:
  $element.on('click', () => {
    $('html, body').animate(
      {
        scrollTop: 0
      },
      500
    );
    Log.log('scrolling to top from button--fixed... ');
  });

  // show only if user scroll down
  document.addEventListener(
    'scroll',
    () => {
      if (document.body.scrollTop > 350 || document.documentElement.scrollTop > 350) {
        $element.removeClass('hiddenImportant');
      } else {
        $element.addClass('hiddenImportant');
      }
    },
    false
  );
}

vfde.utilities.registerUtility(showOnScroll, 'showOnScroll');
