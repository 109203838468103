import $ from 'jquery';
import { setTimeout } from '../globals';
import { parseJSON } from '../utils/json';
import { isRequired, TYPES } from '../utils/types';
import { dispatchGlobalEvent } from '../utils/events';
import { Log } from '../utilities/Logger';

const legacy = {
  inputField: 'input.calculateWithFactor',
  hiddenInputListener: 'input.setHiddenFieldValue',
  zipInputField: 'input.zipCodeChange',
  zipreload: 'page.zipcodeChangeReload',
  gasLabel: 'label.gas',
  numberLabel: 'label.number',
  electricityLabel: 'label.electricity',
  slider: 'input.slider',
  toggle: 'element.toggle',
  tracking: 'element.tracking',
  toggleBorderCase: 'tariff.toggleBorderCase',
  setValue: 'input.setValue'
};
const settings = {
  attr: 'data-reactive',
  postfix: '-loaded'
};
const list = {};

// private functions
function _readBehaviourConfig(configuration) {
  return parseJSON(configuration, 'behaviours');
}

function _runRegisteredBehaviour(name, config, element) {
  // is there a mapping to a behaviour in the new form?
  const run = legacy[name] || name;

  list[run](element, config);
}

function _done(selector) {
  if (typeof selector === 'string') {
    const eventName = `${selector ? `${selector}.` : ''}behaviours:loaded`;

    function _dispatchEvent() {
      dispatchGlobalEvent(eventName);
    }
    setTimeout(_dispatchEvent, 10);
  }
}

// public functions
function register(
  behaviour = isRequired('behaviour', TYPES.FN),
  name = isRequired('name', TYPES.STR)
) {
  list[name] = behaviour;
}

function load(selector = '') {
  const selected =
    typeof selector === 'string' ? `${selector} [${settings.attr}]` : selector;

  $(selected).each((i, workingElement) => {
    const $this = $(workingElement);
    const configuration = $this.attr(settings.attr);
    const behaviourConfigList = _readBehaviourConfig(configuration);

    // there is no need to keep data-reactive on elements
    $this
      .attr(`${settings.attr}${settings.postfix}`, configuration)
      .removeAttr(settings.attr);

    for (let behaviourName in behaviourConfigList) {
      try {
        _runRegisteredBehaviour(
          behaviourName,
          behaviourConfigList[behaviourName],
          workingElement
        );
      } catch (e) {
        Log.error(`could not load behaviour: ${behaviourName}`, e);
      }
    }
  });

  _done(selector);
}

export const behaviours = {
  register,
  registerBehaviour: register, // TODO: legacy name... use `register` instead
  load,
  list,
  legacy
};
