import $ from 'jquery';
import PubSub from 'pubsub-js';

import vfde from '../vfde';
import { Log } from './Logger';

/**
 * check if input is not empty
 * @param {Object} element - jQuery object of dom element
 * @param {Object} rules - JSON config object from behaviour
 * @return {Boolean} return true if validation passed
 */
function validateEmpty($element, rules) {
  // radio and checkbox
  if ($element.attr('type') === 'radio' || $element.attr('type') === 'checkbox') {
    // we need to go to '.form__control' and check if at least one radio is selected
    const $radios = $element.parents('.form__control').find('input');
    // we need to assume negative case and update to true when first checked is found
    let result = false;
    $radios.each(function (index) {
      if (this.checked === true) {
        result = true;
      }
    });
    return result;
  }

  // normal inputs like text,textarea or select
  if ($element.val() === '') {
    return false;
  }

  return true;
}
/**
 * check if input is matching regular expression in validation rule
 * should be used as general validation rule for regex expression validation
 * @param {Object} element - jQuery object of dom element
 * @param {Object} rules - JSON config object from behaviour
 * @return {Boolean} return true if validation passed
 */
function validateRegex(element, rules) {
  const regexStr = new RegExp(rules.regex);
  if (!regexStr.test(element.val())) {
    return false;
  }
  return true;
}
/**
 * check if house number is matching 'negated' regular expression
 * we use negation here as not possible in regular expression otherwise complex regex for house number
 * @param {Object} element - jQuery object of dom element
 * @param {Object} rules - JSON config object from behaviour
 * @return {Boolean} return true if validation passed
 */
function validateHouseNumber(element, rules) {
  const regexStr = new RegExp(rules.regex);
  if (regexStr.test(element.val())) {
    return false;
  }
  return true;
}
/**
 * check if mail address is in the correct format
 * complex validation which is using same rules like on backend side
 * @param {Object} element - jQuery object of dom element
 * @param {Object} rules - JSON config object from behaviour
 * @return {Boolean} return true if validation passed
 */
function validateMail(element, rules) {
  const re = new RegExp(
    '^([a-zA-Z0-9_\\-\\.]+)@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.)|(([a-zA-Z0-9\\-]+\\.)+))([a-zA-Z]{2,6}|[0-9]{1,3})(\\]?)$'
  );
  return re.test(element.val());
}

/**
 * check if PLZ field is 5 digits value
 * @param {Object} element - jQuery object of dom element
 * @param {Object} rules - JSON config object from behaviour
 * @return {Boolean} return true if validation passed
 */
function validatePLZ(element, rules) {
  const re = /\b\d{5}\b/;
  return re.test(element.val());
}

/**
 * check if file is good type and have proper size
 * @param {Object} element - jQuery object of dom element
 * @param {Object} rules - JSON config object from behaviour
 * @return {Boolean} return true if validation passed
 */
function validateFile(element, rules) {
  const {
    maxFileSize = 5242880, // 5mb
    types = null
  } = rules;

  const input = element[0];

  // nothing to check
  if (input.files.length === 0) {
    return true;
  }

  const acceptedTypes =
    (types && types.split(',').map(type => `.${type.trim()}`)) || [];
  const re = new RegExp('(' + acceptedTypes.join('|').replace(/\./g, '\\.') + ')$');

  const isAcceptedExtension = fileName => {
    return re.test(fileName);
  };

  const fileSize = input.files[0].size;

  if (fileSize < maxFileSize) {
    if (acceptedTypes) {
      const isAccepted = isAcceptedExtension(input.files[0].name);

      if (isAccepted) {
        const inputFileName = element
          .closest('.form__element')
          .find('.form__input__file-name');
        inputFileName.text(input.files[0].name);
        return true;
      }

      return false;
    }

    return true;
  }

  input.value = '';

  return false;
}

/**
 * check if field looks like phone number
 * Allowed characters: digits from "0-9", "-", "/", "+", "[empty space]"
 *
 * @param {Object} element - jQuery object of dom element
 * @param {Object} rules - JSON config object from behaviour
 * @return {Boolean} return true if validation passed
 */
function validatePhoneNumber(element, rules) {
  const re = new RegExp('^(\\+?[0-9\\- \\/]+)$');
  return re.test(element.val());
}

/**
 * check if minimum lenght of input is fulfilled
 * @param {Object} element - jQuery object of dom element
 * @param {Object} rules - JSON config object from behaviour
 * @return {Boolean} return true if validation passed
 */
function validateMinlength(element, rules) {
  if (element.val().length < rules.min) {
    return false;
  }
  return true;
}
/**
 * check if maximum lenght of input is fulfilled
 * @param {Object} element - jQuery object of dom element
 * @param {Object} rules - JSON config object from behaviour
 * @return {Boolean} return true if validation passed
 */
function validateMaxlength(element, rules) {
  if (element.val().length > rules.max) {
    return false;
  }
  return true;
}
/**
 * check if minimum value of input is fulfilled
 * @param {Object} element - jQuery object of dom element
 * @param {Object} rules - JSON config object from behaviour
 * @return {Boolean} return true if validation passed
 */
function validateMinval(element, rules) {
  if (Number(element[0].value) < rules.min) {
    return false;
  }
  return true;
}
/**
 * check if attribute in rules.name is set to true in more custom complex validation
 * @param {Object} element - jQuery object of dom element
 * @param {Object} rules - JSON config object from behaviour
 * @return {Boolean} return true if validation passed
 */
function attributeValidation(element, rules) {
  if ($(element).attr(rules.name) === 'false') {
    // If typeahead got more than one option and not chosen then ..
    // ... make it visible with error
    if (rules.name === 'data-typeahead-selected') {
      // if mobile detected then we should make jump for more space
      // and clear soft keyboard
      if (window.vfde.isMobile) {
        // -50px because some browser can have url input present
        // maybe if we check old android and no find then we will decrease or remove it
        // so far in simulator url field from browser is present
        $('html, body').scrollTop($(element).offset().top - 50);
        // jump done so hiding now
        //$(element).data('ttTypeahead')._onUpKeyed();
        // onQuery changed safet than keyUp so no value change is possible
      }
      // To have proper event execution in IE
      // and no mobile
      // window phone should be tested some day:)
      setTimeout(function () {
        const tempVal = $(element).val();
        $(element).val('');
        $(element).trigger('keyup').val(tempVal).trigger('keyup');
      }, 0);
    }
    return false;
  }
  return true;
}
/**
 * Mapping object mapped for ex. required from config to method validateEmpty
 *
 */
const checks = {
  required: validateEmpty,
  minlength: validateMinlength,
  maxlength: validateMaxlength,
  minval: validateMinval,
  attribute: attributeValidation,
  allowedRegex: validateRegex,
  houseNumber: validateHouseNumber,
  validateMail: validateMail,
  validatePLZ: validatePLZ,
  validateFile: validateFile,
  validatePhoneNumber: validatePhoneNumber
};
/**
 * Internal method show error summary of not passed validations,
 * in current requirements we are setting rules in that way that only error is shown
 * @param {Object} element - jQuery object of dom element
 * @param {Boolean} passed - determine if validation pass or not
 * @param {Array} messages - List of error message to shown
 */
function _showValidationSummary(element, passed, messages) {
  const formElem = element.closest('.form__element');

  if (passed) {
    formElem.removeClass('validation--error');
    formElem.find('.form__errorText').html('');
  } else {
    formElem.addClass('validation--error');
    formElem.find('.form__errorText').html(messages.join('<br/>'));
    element.closest('form').find('#generalError').addClass('validation--error');
  }

  PubSub.publish('validate.showValidationSummary', { element, passed, messages });
}
/**
 * main function which perform validation applying rules defined above
 * @param  {string} element selector
 * @return {Boolean} return true if validation passed
 */
export const validate = function (element) {
  /** {jQuery object} $element - jQuery object based on selector */
  const $element = $(element);
  /** {Boolean} passed - store validation result */
  let passed = true;
  let errors = [];
  /** {JSON} config - read config from data-validate attribute */
  let config = {};
  // try to parse JSON
  // when validation is not present '{}' prevents from error while parsing
  let attrData = $element.attr('data-validate') || '{}';
  try {
    config = JSON.parse(attrData);
  } catch (error) {
    Log.error('validate.js wrong json provided:', attrData, 'in:', $element);
    Log.error(error);
  }

  for (var index in config.rules) {
    if (config.rules[index].length === undefined) {
      if (typeof checks[index] === 'function') {
        var test = checks[index]($element, config.rules[index]);
        if (!test) {
          errors.push(config.rules[index].msg);
        }
        passed = passed && test;
      }
    } else {
      // attribute array so type object
      for (var i = 0; i < config.rules[index].length; i++) {
        var test = checks[index]($element, config.rules[index][i]);
        if (!test) {
          errors.push(config.rules[index][i].msg);
        }
        passed = passed && test;
      }
    }
  }

  _showValidationSummary($element, passed, errors);

  return { passed, noScroll: config.disableScrollIfInvalid || false };
};
export default validate;

vfde.utilities.registerUtility(validate, 'validate');
