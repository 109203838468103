import $ from 'jquery';
import svg4everybody from 'svg4everybody';
import objectFitImages from 'object-fit-images';

import { isIE } from '../vfde/isIE';
import { inIframe } from '../vfde/inIframe';

// polyfill for svg external file icons in IE9-11
// do not run in Smart Edit or if page is used as iframe
export function svg4everybodyPolyfill() {
  if (!inIframe) {
    svg4everybody();
  }
}

// polyfill for object-fit property in IE9+
export function objectFit() {
  if (isIE()) {
    const selector = 'img.image--cover';
    // fix for images using `image--cover` class but not having `src` attr set
    // (eg. in .carousel component)
    $(selector).each((i, img) => {
      const $img = $(img);

      if (!$img.attr('src')) {
        const src = $img.data('src') || $img.data('lazy') || $img.attr('src-lazy');
        $img.attr('src', src);
      }
    });

    objectFitImages(selector);
  }
}

// polyfill for Element.matches() method for IE9+
// https://developer.mozilla.org/en-US/docs/Web/API/Element/matches#Polyfill
function elementMatches() {
  if (!Element.prototype.matches) {
    Element.prototype.matches = Element.prototype.msMatchesSelector;
  }
}

function elementIncludes() {
  if (!String.prototype.includes) {
    String.prototype.includes = function (search, start) {
      if (typeof start !== 'number') {
        start = 0;
      }

      if (start + search.length > this.length) {
        return false;
      } else {
        return this.indexOf(search, start) !== -1;
      }
    };
  }

  if (!Array.prototype.includes) {
    Object.defineProperty(Array.prototype, 'includes', {
      enumerable: false,
      value: function (obj) {
        var newArr = this.filter(function (el) {
          return el == obj;
        });
        return newArr.length > 0;
      }
    });
  }
}
// Object.assign polyfill
// https://developer.mozilla.org/en/docs/Web/JavaScript/Reference/Global_Objects/Object/assign#Polyfill
function objectAssign() {
  if (typeof Object.assign != 'function') {
    Object.assign = function (target, varArgs) {
      // .length of function is 2
      'use strict';
      if (target == null) {
        // TypeError if undefined or null
        throw new TypeError('Cannot convert undefined or null to object');
      }

      var to = Object(target);

      for (var index = 1; index < arguments.length; index++) {
        var nextSource = arguments[index];

        if (nextSource != null) {
          // Skip over if undefined or null
          for (var nextKey in nextSource) {
            // Avoid bugs when hasOwnProperty is shadowed
            if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
              to[nextKey] = nextSource[nextKey];
            }
          }
        }
      }
      return to;
    };
  }
}

function elementClosest() {
  if (!Element.prototype.closest) {
    Element.prototype.closest = function (s) {
      var el = this;

      do {
        if (el.matches(s)) return el;
        el = el.parentElement || el.parentNode;
      } while (el !== null && el.nodeType === 1);
      return null;
    };
  }
}

function next() {
  if (!Element.prototype.next) {
    Element.prototype.next = function (selector) {
      const nextElem = this.nextElementSibling;

      // If there's no selector, return the next element
      if (!selector) {
        return nextElem;
      }

      // Otherwise, check if the element matches the selector
      if (nextElem && nextElem.matches(selector)) {
        return nextElem;
      }

      // if it's not a match, return null
      return null;
    };
    // Get the next element
  }
}

function elementParents() {
  if (!Element.prototype.parents) {
    Element.prototype.parents = function (selector) {
      // Set up a parent array
      let parents = [];
      let self = this;

      // Push each parent element to the array
      for (; self && self !== document; self = self.parentNode) {
        if (selector) {
          if (self.matches(selector)) {
            parents.push(self);
          }
          continue;
        }
        parents.push(self);
      }

      // Return our parent array
      return parents;
    };
  }
}

function arrayFrom() {
  /**
   * Array.from() polyfill
   */
  // From https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/from
  // Production steps of ECMA-262, Edition 6, 22.1.2.1
  if (!Array.from) {
    Array.from = (function () {
      var toStr = Object.prototype.toString;
      var isCallable = function (fn) {
        return typeof fn === 'function' || toStr.call(fn) === '[object Function]';
      };
      var toInteger = function (value) {
        var number = Number(value);
        if (isNaN(number)) {
          return 0;
        }
        if (number === 0 || !isFinite(number)) {
          return number;
        }
        return (number > 0 ? 1 : -1) * Math.floor(Math.abs(number));
      };
      var maxSafeInteger = Math.pow(2, 53) - 1;
      var toLength = function (value) {
        var len = toInteger(value);
        return Math.min(Math.max(len, 0), maxSafeInteger);
      };

      // The length property of the from method is 1.
      return function from(arrayLike /*, mapFn, thisArg */) {
        // 1. Let C be the this value.
        var C = this;

        // 2. Let items be ToObject(arrayLike).
        var items = Object(arrayLike);

        // 3. ReturnIfAbrupt(items).
        if (arrayLike == null) {
          throw new TypeError(
            'Array.from requires an array-like object - not null or undefined'
          );
        }

        // 4. If mapfn is undefined, then let mapping be false.
        var mapFn = arguments.length > 1 ? arguments[1] : void undefined;
        var T;
        if (typeof mapFn !== 'undefined') {
          // 5. else
          // 5. a If IsCallable(mapfn) is false, throw a TypeError exception.
          if (!isCallable(mapFn)) {
            throw new TypeError(
              'Array.from: when provided, the second argument must be a function'
            );
          }

          // 5. b. If thisArg was supplied, let T be thisArg; else let T be undefined.
          if (arguments.length > 2) {
            T = arguments[2];
          }
        }

        // 10. Let lenValue be Get(items, "length").
        // 11. Let len be ToLength(lenValue).
        var len = toLength(items.length);

        // 13. If IsConstructor(C) is true, then
        // 13. a. Let A be the result of calling the [[Construct]] internal method
        // of C with an argument list containing the single item len.
        // 14. a. Else, Let A be ArrayCreate(len).
        var A = isCallable(C) ? Object(new C(len)) : new Array(len);

        // 16. Let k be 0.
        var k = 0;
        // 17. Repeat, while k < len… (also steps a - h)
        var kValue;
        while (k < len) {
          kValue = items[k];
          if (mapFn) {
            A[k] =
              typeof T === 'undefined' ? mapFn(kValue, k) : mapFn.call(T, kValue, k);
          } else {
            A[k] = kValue;
          }
          k += 1;
        }
        // 18. Let putStatus be Put(A, "length", len, true).
        A.length = len;
        // 20. Return A.
        return A;
      };
    })();
  }
}

export function forEachOnObject() {
  if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = Array.prototype.forEach;
  }
}

export default function () {
  objectAssign();
  elementMatches();
  elementIncludes();
  svg4everybodyPolyfill();
  objectFit();
  elementClosest();
  elementParents();
  forEachOnObject();
  arrayFrom();
  next();
}
