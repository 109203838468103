import $ from 'jquery';

import { linesCount } from '../utilities/linesCount';

const SELECTOR = '[data-count-lines]';

export function countLines() {
  const $elementsToCountLines = $(SELECTOR);

  if ($elementsToCountLines.length > 0) {
    linesCount($elementsToCountLines);
  }
}
