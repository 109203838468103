import $ from 'jquery'; // TODO: remove after full migration

import { document, window } from './globals'; // TODO: remove after full migration
import { Log } from './utilities/Logger';
import vfde from './vfde'; // TODO: remove after full migration
import { init } from './init';

import './jQueryPlugins'; // loads all jQuery plugins that we use
import './utilities'; // registers all the utilities

import './templates'; // load legacy namespaced hbs templates // TODO: remove this line when all templates get imported in the files they're used

window.jQuery = window.$ = $; // globally exposes jQuery for legacy issues // TODO: remove after full migration
window.vfde = vfde; // TODO: remove after full migration
window.Log = Log; // TODO: remove after full migration

// removing 'no-js' class from html tag, as we have JS working ;)
const html = document.getElementsByTagName('html')[0];
html.className = html.className.replace(/\bno-js\b/, 'js');

// starts our app
init();
