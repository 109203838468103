import queryParams from '../vueProducts/helpers/queryParams';

//
// values for tracking prams check are configured in Flexible Content Documents in hippo
// Data Track Name field using syntax: paramName|domain(optional): e.g. trackid or trackid|vattenfall.telekom.de
//

const initCampaignTracking = () => {
  const anchors = document.querySelectorAll('[data-track]:not([data-track=""])');
  const urlParams = queryParams.getQueryParams();
  const passParamToHref = (item, trackingValues, urlParams) => {
    const param = trackingValues[0];
    const value = urlParams[trackingValues[0]];
    const queryToPass = `${param}=${value}`;

    if (item.href.includes(param)) {
      //we need to overwrite it if default value alread set
      const hrefParams = new URLSearchParams(item.href.substr(item.href.indexOf('?')));
      const oldValue = hrefParams.get(param);
      item.href = item.href.replace(oldValue, value);
    }

    if (!item.href.includes(queryToPass)) {
      item.href = item.href.includes('?')
        ? `${item.href}&${param}=${value}`
        : `${item.href}?${param}=${value}`;
    }
  };

  for (let item of anchors) {
    let trackingData = item.getAttribute('data-track');
    if (trackingData) {
      try {
        trackingData = JSON.parse(trackingData);
      } catch (e) {
        trackingData = {};
      }

      if (!trackingData.name || trackingData.name === 'PutTrackingName') continue;

      // read if default value needs to add to url no matter of domain
      // if it is the case then remove ()
      trackingData.name = addDefaultValueToUrl(trackingData.name, item);
      const trackingValues = trackingData.name.split('|');
      if (!trackingValues || trackingValues === null || urlParams === null) continue;

      const trackingParamKeyExists = Object.keys(urlParams).includes(trackingValues[0]);

      if (urlParams && trackingParamKeyExists && trackingValues.length) {
        const domain = trackingValues[1] || null;

        if (domain) {
          if (item.href.includes(domain)) {
            passParamToHref(item, trackingValues, urlParams);
          }
        } else {
          passParamToHref(item, trackingValues, urlParams);
        }
      }
    }
  }
};

const addDefaultValueToUrl = (nameTracking, elem) => {
  // expected format string, string(val), string(val)|domain string|domain
  // let's extract val from string(val)|domain
  const matches = nameTracking.match(/\((.*?)\)/);
  if (matches) {
    const defaultValueToAdd = matches[1];
    const defaultKeyToAdd = nameTracking.split('(')[0];
    let domain = nameTracking.split('|')[1] || null;
    // first checking if this key is not already added
    if (
      !elem.href.includes(defaultValueToAdd) &&
      !elem.href.includes(defaultKeyToAdd)
    ) {
      // last check for domain presence after |
      if (domain) {
        // check if href is valid according to domain
        if (elem.href.includes(domain)) {
          // just before adding it to href we should check if it should be overwrite by passed url
          elem.href = elem.href.includes('?')
            ? `${elem.href}&${defaultKeyToAdd}=${defaultValueToAdd}`
            : `${elem.href}?${defaultKeyToAdd}=${defaultValueToAdd}`;
        }
      } else {
        // no domain so just add default value
        elem.href = elem.href.includes('?')
          ? `${elem.href}&${defaultKeyToAdd}=${defaultValueToAdd}`
          : `${elem.href}?${defaultKeyToAdd}=${defaultValueToAdd}`;
      }
    }
    return nameTracking.replace(`(${defaultValueToAdd})`, '');
  } else {
    return nameTracking;
  }
};

export { initCampaignTracking };
