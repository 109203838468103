import $ from 'jquery';
import PubSub from 'pubsub-js';

import { Log } from './Logger';
import { vfde } from '../vfde';

import { window } from '../globals';

export function onLoginOpen($elementsArray, topic) {
  $elementsArray.each((i, el) => {
    $(el).on('click', addEvent);
  });

  PubSub.subscribe(topic, addEvent);
}

function addEvent() {
  if ('dataLayer' in window && typeof window.dataLayer.push === 'function') {
    window.dataLayer.push({ event: 'login-modal-open' });
  } else {
    Log.error('dataLayer is not defined or dataLayer.push is not a function');
  }
}

vfde.utilities.registerUtility(onLoginOpen, 'onLoginOpen');
