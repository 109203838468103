import $ from 'jquery';

import { navigator } from '../globals';

/** {Boolean} -  return true if detected IE */
export const isIE = () => {
  const _isIE =
    navigator.appName == 'Microsoft Internet Explorer' ||
    !!(navigator.userAgent.match(/Trident/) || navigator.userAgent.match(/rv:11/));

  $('html').addClass(`${_isIE ? '' : 'no-'}IE`);

  return _isIE;
};

export default isIE();
