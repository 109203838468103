(function (vfde) {
  'use strict';
  /**
   * Clear validation message and restore it state to initial one
   * @param  {string} element selector
   */
  var validateClear = function (element) {
    /** {Object} formElem - jQuery object form element in which element is located */
    var formElem,
      /** {Object} config - string from DOM with data-validate attribute which is parsed to JSON */
      config,
      /** {Number} i - just loop iterator */
      i,
      /** {Object} $element - just jQuery object from passed element */
      $element = $(element);

    formElem = $element.closest('.form__element');
    formElem.removeClass('validation--error');
    formElem.find('.form__control').removeClass('loading');
    formElem.find('.form__errorText').html('');
    // check what attributes must be removed
    try {
      config = JSON.parse($element.attr('data-validate'));
      if (typeof config.rules.attribute === 'object') {
        for (i = 0; i < config.rules.attribute.length; i++) {
          $element.removeAttr(config.rules.attribute[i].name);
        }
      }
    } catch (e) {
      //we ignore this error
    }
  };

  vfde.utilities.registerUtility(validateClear, 'validateClear');
})(window.vfde);
