/**
 * {{Object}} - url to FE
 * ex.:
 * {
 *   scheme: 'https',
 *   authority: 'vattenfallde-fe-static-tst.azurewebsites.net',
 *   path: '//fe-version/scripts/main.js',
 *   query: undefined,
 *   fragment: undefined
 * }
 */
export const feUri = (path = '/scripts/main.js') => {
  const scriptArray = document.getElementsByTagName('script');
  const pattern = RegExp('^(([^:/?#]+):)?(//([^/?#]*))?([^?#]*)(\\?([^#]*))?(#(.*))?');

  if (scriptArray.length) {
    for (let i = 0, len = scriptArray.length; i < len; i++) {
      let src = scriptArray[i].src;
      const isUrlWithPath = src.includes(path);

      if (isUrlWithPath) {
        const matches = src.match(pattern);
        const result = {
          scheme: matches[2],
          authority: matches[4],
          path: matches[5],
          query: matches[7],
          fragment: matches[9]
        };
        return result;
      }
    }
  }

  return {};
};

export default feUri();
