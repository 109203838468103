import $ from 'jquery';
import PubSub from 'pubsub-js';

import { vfde } from '../vfde';
import { Log } from './Logger';

const attr = 'data-pubSubRegister';
const selector = `[${attr}]`;

export const addPubSub = (element, config) => {
  // const $element = config.selector ? $(element.find(config.selector)[0]) : element;
  const el = config.selector ? element.querySelector(config.selector) : element;

  el.addEventListener(config.event, event => {
    event.preventDefault();
    PubSub.publish(config.topic, config.data);
  });
};

export function buttonPubSub(parent = document.querySelector('body')) {
  const parentRange = parent[0] || parent;

  const registerToElements = parentRange.querySelectorAll(selector);

  registerToElements.forEach((el, i) => {
    let config = el.getAttribute(attr);

    try {
      config = JSON.parse(config);
    } catch (err) {
      Log.error('data-pubSubRegister should be JSON string');
      Log.error(err);
      return;
    }

    addPubSub(el, config);
  });
}

export default buttonPubSub;

vfde.utilities.registerUtility(buttonPubSub, 'buttonPubSub');
