import $ from 'jquery';

import { navigator } from '../globals';

/** {Boolean} -  return true if userAgent match apple products set class on html */
export const isIOS = () => {
  const _isIOS = !!navigator.userAgent.match(/iPhone|iPad|iPod/i);

  $('html').addClass(`${_isIOS ? '' : 'no-'}iOS`);

  return _isIOS;
};

export default isIOS();
