const allowedParamsPrefix = 'utm_';
const crossdomainParam = 'crossdomainid';

const createSrcWithParams = (pageParams, iframeSrc) => {
  const paramsArr = pageParams.split('&');
  let paramsToPass = [];
  let iframeSrcWithParams;

  // remove all params except this with allowed prefix
  paramsArr.forEach(param => {
    if (param.startsWith(allowedParamsPrefix)) {
      paramsToPass.push(param);
    }
  });

  const paramsToPassJoin = paramsToPass.join('~');
  const iframeSrcSplit = iframeSrc.split('?');

  // if crossdomainParam exists in iframe src, remove it and add passed params from page url
  if (iframeSrc.includes(crossdomainParam)) {
    const allIframeParams = new URLSearchParams(iframeSrcSplit[1]);
    allIframeParams.delete(crossdomainParam);

    iframeSrcWithParams = `${
      iframeSrcSplit[0]
    }?${allIframeParams.toString()}&${crossdomainParam}=${paramsToPassJoin}`;
  } else {
    const paramSeparator = iframeSrcSplit[1] ? '&' : '?';
    iframeSrcWithParams = `${iframeSrc}${paramSeparator}${crossdomainParam}=${paramsToPassJoin}`;
  }

  return iframeSrcWithParams;
};

const addParamsFromUrlToIframe = () => {
  const pageUrl = window.location.toString();
  const pageParams = pageUrl.split('?')[1];
  const iframeWrappers = document.getElementsByClassName('utm_iframe');

  Array.from(iframeWrappers).forEach(wrapper => {
    const iframe = wrapper.querySelector('iframe');
    const iframeSrc = iframe.dataset.src;

    if (pageParams) {
      const iframeSrcWithParams = createSrcWithParams(pageParams, iframeSrc);

      iframe.src = iframeSrcWithParams;
    } else {
      iframe.src = iframeSrc;
    }
  });
};

export { addParamsFromUrlToIframe };
