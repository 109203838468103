import $ from 'jquery';

import { showOnScroll as util } from '../utilities/showOnScroll';

const SELECTOR = '[data-show-scroll]';

export function showOnScroll() {
  const $elementsShowOnScroll = $(SELECTOR);

  if ($elementsShowOnScroll.length > 0) {
    util($elementsShowOnScroll);
  }
}
