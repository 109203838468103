import PubSub from 'pubsub-js';
import getGUID from '../utilities/guid';
import vfde from '../vfde';

export function processingParams(...args) {
  const me = getGUID();
  const config = args[1]; /* because element argument is not used */
  const topic = config.topic;
  const behaviorEvent = config.event;
  const param = config.param;

  function getNewValue(param) {
    const paramsString = document.location.search.substring(
      1,
      document.location.search.length
    );
    const params = paramsString.split(/[(.*)&]/gi);
    let paramSet = [];

    if (paramsString.length > 0) {
      for (let p in params) {
        let dataSet = params[p].split(/[(.*)=]/gi);
        paramSet[dataSet[0]] = dataSet[1].replace(/\+/gi, ' ');
        if (dataSet[0] === param) {
          return dataSet[1];
        }
      }
    }
  }

  document.addEventListener(behaviorEvent, event => {
    PubSub.publish(topic, {
      me: me,
      newValue: getNewValue(param)
    });
  });
}

export default processingParams;

vfde.behaviours.register(processingParams, 'page.processingParams');
