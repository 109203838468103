import { headersCommon, getDefaultRequest } from '../../helpers/config';
import { generateUUID } from '../../helpers/guid';
import { getDavisConfig } from './account';
import { prepareHeaders } from '../../helpers/headers';
import { fields } from '../structure/places';
import { fields as addressFields } from '../structure/address';

let davisConfig = {};

const mapPlaces = res => {
  const data = res.Result[fields.places];

  const places = {
    message: parseMessage(res, fields),
    result: {
      places: data.map(p => ({
        name: p[fields.place.name],
        zipCode: p[fields.place.zipCode],
        isBlacklisted: p[fields.place.isBlacklisted],
        isBordercase: p[fields.place.isBordercase]
      }))
    },
    statusCode: res[fields.statusCode]
  };

  return places;
};

const mapAddresses = res => {
  const data = res.Result[addressFields.addresses];

  const addresses = {
    message: parseMessage(res, addressFields),
    result: {
      addresses: data.map(p => ({
        place: p[addressFields.address.place],
        zipCode: p[addressFields.address.zipCode],
        houseNo: p[addressFields.address.houseNo],
        street: p[addressFields.address.street],
        district: p[addressFields.address.district]
      }))
    },
    statusCode: res[addressFields.statusCode]
  };

  return addresses;
};

const parseMessage = (res, fields) => {
  return res[fields.messages] && res[fields.messages].length
    ? res[fields.messages][0][fields.message.description]
    : '';
};

export default {
  async getPlaces(query) {
    const { tokenData } = await prepareHeaders();
    davisConfig = await getDavisConfig();

    const headers = {
      ...headersCommon,
      'Ocp-Apim-Subscription-Key': davisConfig.subscriptionKey,
      'davis-token': tokenData.token
    };

    const guid = generateUUID();
    const defaultRequest = getDefaultRequest(davisConfig, tokenData, guid);
    const body = {
      ...defaultRequest,
      Request: {
        PLZ: query.zipCode,
        Ort: query.place,
        Sparte: query.energyType
      }
    };

    return fetch(
      `${davisConfig.serverPath}${davisConfig.newProductPath}Adressen/GetOrte`,
      {
        method: 'POST',
        headers,
        body: JSON.stringify(body)
      }
    )
      .then(response => response.json())
      .then(res => {
        const mappedResult = mapPlaces(res);
        return mappedResult;
      })
      .catch(e => {
        Log.error('error', e);
      });
  },

  async getAddress(query) {
    const { tokenData } = await prepareHeaders();
    davisConfig = await getDavisConfig();

    const headers = {
      ...headersCommon,
      'Ocp-Apim-Subscription-Key': davisConfig.subscriptionKey,
      'davis-token': tokenData.token
    };

    const guid = generateUUID();
    const defaultRequest = getDefaultRequest(davisConfig, tokenData, guid);
    const body = {
      ...defaultRequest,
      Request: {
        Adresse: {
          PLZ: query.zipCode,
          Ort: query.place,
          Strasse: query.street
        }
      }
    };

    return fetch(
      `${davisConfig.serverPath}${davisConfig.newProductPath}Adressen/GetAdresse`,
      {
        method: 'POST',
        headers,
        body: JSON.stringify(body)
      }
    )
      .then(response => response.json())
      .then(res => {
        const mappedResult = mapAddresses(res);
        return mappedResult;
      })
      .catch(e => {
        Log.error('error', e);
      });
  }
};
