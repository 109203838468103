import vfde from '../vfde';

export function linesCount($elementArray) {
  $elementArray.each((i, el) => {
    const $elem = $(el);
    const $elemToCount = $elem.find($elem.data('count-lines'));
    const divHeight = $elemToCount.outerHeight();
    const lineHeight = parseInt($elemToCount.css('line-height'), 10);
    const lines = divHeight / lineHeight;

    $elem.attr('data-lines-number', Math.ceil(lines));
  });
}

export default linesCount;

vfde.utilities.registerUtility(linesCount, 'linesCount');
