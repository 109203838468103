import $ from 'jquery';

import { document } from '../globals';

/** {Boolean} - evaluated by immediate function to detect mobile (do not rely 100% on this method touchable monitors) */
export const isMobile = () => {
  let _isMobile;

  try {
    _isMobile = !!document.createEvent('TouchEvent');
  } catch (e) {
    _isMobile = false;
  }

  $('html').addClass(`${_isMobile ? '' : 'no-'}touch`);

  return _isMobile;
};

export default isMobile();
