import vfde from '../vfde';

// private
const s4 = () =>
  Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .slice(1);
const s8 = () => [s4(), s4()].join('');
const s12 = () => [s8(), s4()].join('');
const guid = () => [s8(), s4(), s4(), s4(), s12()].join('-');

// public
export const getGUID = () => guid();
export default getGUID;

vfde.utilities.registerUtility(getGUID, 'getGUID');
