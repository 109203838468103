import dictionary from '../utilities/SignsWidthDictionary';

export function TypeaheadCustom($element, Log, config) {
  if (!config.name.length) {
    Log.error('Typeahead config read error: name not found', config);
  }

  function shiftDataContent() {
    const signs = $element.val().split('');
    let typeaheadLength = 17; // default spacing

    signs.forEach(sign => {
      typeaheadLength += dictionary[sign] || 12;
    });

    // add proper class
    $element.parent().attr('data-content', config.name);
    $element
      .parent()
      .attr('style', `--typeahead-length: ${typeaheadLength.toFixed(2)}px`);
  }

  shiftDataContent();

  $element.on('keyup', function () {
    shiftDataContent();
  });

  $element.on('load', function () {
    shiftDataContent();
  });
}

export default TypeaheadCustom;
