const eventTypes = {
  calcTariff: 'calc_tarif'
};

import { window } from '../globals';

export const formSubmitTracking = (trackingEvent, trackingData) => {
  let dataLayerConfig = {};

  switch (trackingEvent) {
    case 'calcTariff':
      dataLayerConfig = {
        event: eventTypes[trackingEvent],
        tariffData: trackingData
      };
      break;

    default:
      break;
  }

  if ('dataLayer' in window && typeof window.dataLayer.push === 'function') {
    window.dataLayer.push(dataLayerConfig);
  }
};

export default formSubmitTracking;
