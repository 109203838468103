import $ from 'jquery';
import { PubSub } from 'pubsub-js';
import Handlebars from 'handlebars';
import { vfde } from '../vfde';
import { setTimeout } from '../globals';
import { promotionTracking } from '../utilities/promotionTracking';

/**
 * Created by htk09 on 2017-01-31.
 */

/**
 *
 * @param element{Element} object to attach event.
 * @param config{Object} contains `template` and `context` property. `template` property is name of client template handelbars file or css id of element that contain valid html markup.
 * @description This behaviur allows to call modal with given template and context by click event. Template may use handlebars markup and `context` property of `config` object will be used as data for this markup. See example http://c036378:81/docs/new-design/popup-loginbox
 */
export const modal = (element, config) => {
  const PERSONAL_DATA_FORM = 'personal-data-form';

  const $element = $(element);
  const template = config.template;
  const context = config.context;

  let templateHBS;
  let bodyLastPosition = 0;

  if (template === PERSONAL_DATA_FORM && context.dataTrack && context.dataTrack.name) {
    context.dataTrack.name = `${context.dataTrack.name}_submit`;
    context.dataTrack = JSON.stringify(context.dataTrack);
    Log.warn('::Quiz - modal data tracking: ', context.dataTrack);
  }

  //extra decoding for GPDR checkboxes from CMS
  if (context.checkboxes && context.checkboxes.gdpr) {
    const obj = context.checkboxes.gdpr;
    Object.keys(obj).forEach(function (key) {
      obj[key] = decodeURIComponent(obj[key]);
      obj[key] = obj[key].replace(/\+/g, ' ');
      if (key === 'error') {
        obj[key] = obj[key].replace(/\'/g, '&#39;');
      }
      if (key === 'label' && obj[key].indexOf('href=')) {
        obj.hrefInLabel = true;
      }
    });
  }

  PubSub.subscribe('closeModalWindow', function () {
    $('.modal').modal('hide');
  });

  if (!template || !context) {
    Log.error(
      "Some required variables are not defined. Required variables are 'template' name or id (with #) and 'context' with template data"
    );
  }

  // prepare template
  templateHBS =
    template.substr(0, 1) === '#'
      ? Handlebars.compile($(template).html())
      : vfde.templates.getTemplate(template);

  if (!templateHBS) {
    Log.error(`template "${template}" was not found`);
  }

  if (config.open) {
    callPopup(context).removeClass('fade').modal({ show: true });
  }

  if (config.event) {
    $element.on(config.event, function (e) {
      e.preventDefault();
      callPopup(context);
    });
  }

  // add support for custom events
  if (config.customEvent) {
    document.addEventListener(
      config.customEvent,
      () => {
        // show only if not blocked by cookie
        const cookieName = template + '-block-' + (context.voucherId || '');
        const cookieExists = vfde.cookies.get(cookieName);

        if (!cookieExists) {
          // set cookie if user see it once then not show more, set 30 days so new newsletter in next prime iteration will work
          vfde.cookies.set(cookieName, 'true', 30);

          if (config.timeout) {
            const delay = parseInt(config.timeout * 1000, 10);
            setTimeout(function () {
              callPopup(context);
            }, delay);
          } else {
            callPopup(context);
          }
        }
      },
      false
    );
  }

  if (config.topic) {
    PubSub.subscribe(config.topic, function (msg, data) {
      const modifiedContext = matchFields(context, data);
      callPopup(modifiedContext);
    });
  }

  function callPopup(context) {
    // close all modals before open new
    $('.modal').modal('hide');

    const $modal = $(templateHBS(context));
    if (vfde.isIOS) {
      // save scroll position
      bodyLastPosition = $('body').scrollTop();
    }
    $modal.modal({ show: true });

    $modal.on('hidden.bs.modal', function () {
      //clean up
      $(this).data('bs.modal', null);
      $(this).remove();
      if (vfde.isIOS) {
        // go back where modal was open
        $('body').scrollTop(bodyLastPosition);
      }
    });

    $modal.on('shown.bs.modal', function () {
      $modal.find('input[type="number"]').each(function (index) {
        vfde.utilities.sanitize(this);
      });

      if (!vfde.isMobile) {
        vfde.utilities.convertTypeNumberToText('input[type="number"]');
        // sanitization should be run only on text fields in non mobile
        // fields like type=number do not support event like text selection etc
        $modal.find('input[type="number"]').each(function (index) {
          vfde.utilities.sanitize(this);
        });
      }

      vfde.controllers.lotteryController.validateFields('[data-formAjax]');
      vfde.utilities.formValidateAjax('[data-validateFormAjax]');
      vfde.utilities.buttonPubSub($modal);
      vfde.init('.modal-dialog');
      promotionTracking($('.modal-dialog')[0]);
    });
    return $modal;
  }

  function matchFields(context, data = {}) {
    const mergedData = {};

    for (let property in context) {
      if (typeof context[property] === 'object') {
        mergedData[property] = matchFields(context[property], data[property] || {});
      } else {
        mergedData[property] = data[property] || context[property];
      }
    }

    return mergedData;
  }
};

vfde.behaviours.register(modal, 'modal');
