export function setGlobalClasses() {
  const $html = document.querySelector('html');
  if (null !== document.querySelector('.calculator')) {
    $html.classList.add('hasProductCalculator');
  }

  if (null !== document.querySelector('.chatBot')) {
    $html.classList.add('hasChatBot');
  }

  if (null !== document.querySelector('.button--moveTop')) {
    $html.classList.add('hasMoveTop');
  }

  if (null !== document.querySelector('.voucher-popup')) {
    $html.classList.add('hasVoucherPopup');
  }
}
