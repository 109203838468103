/**
 * readTracking utility used for read data stored on previous page (in sessionStorage) form and push it to datalayer
 */

import { Log } from './Logger';
import { window, sessionStorage, JSON } from '../globals';
import vfde from '../vfde';

function _pushToDataLayer(data) {
  if ('dataLayer' in window && typeof dataLayer.push === 'function') {
    Log.debug('readTracking: sending such data to dataLayer', JSON.stringify(data));
    dataLayer.push(data);
    sessionStorage.removeItem(vfde.constants.DATALAYER);
    Log.debug('readTracking: data pushed and entry deleted from sessionStorage');
  } else {
    Log.error('dataLayer is not defined or dataLayer.push is not a function');
  }
}

export function readTracking() {
  try {
    const data = JSON.parse(sessionStorage.getItem(vfde.constants.DATALAYER));
    if (!!data) {
      _pushToDataLayer(data);
    } else {
      Log.debug('readTracking: data null so no push');
    }
  } catch (e) {
    Log.warn('readTracking: problem reading sessionStorage', e);
  }
}

export default readTracking;
