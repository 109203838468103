// We use handlebars comparison function specially for quiz component
// which uses the clienttemplates modals
// Functions in '/helper/' folder are working ONLY on the devServer
export function comparison(v1, operator, v2, options) {
  var operators = {
    '==': v1 == v2 ? true : false,
    '===': v1 === v2 ? true : false,
    '!=': v1 != v2 ? true : false,
    '!==': v1 !== v2 ? true : false,
    '>': v1 > v2 ? true : false,
    '>=': v1 >= v2 ? true : false,
    '<': v1 < v2 ? true : false,
    '<=': v1 <= v2 ? true : false,
    '||': v1 || v2 ? true : false,
    '&&': v1 && v2 ? true : false
  };
  if (operators.hasOwnProperty(operator)) {
    if (operators[operator]) {
      return options.fn(this);
    }
    return options.inverse(this);
  }
  return console.error('Error: Expression "' + operator + '" not found');
}

export default comparison;
