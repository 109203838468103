import { getTokenAnonym } from '../vueCommon/services/DI/account';

(function (vfde) {
  'use strict';
  /**
   * format function used for formating tariff value
   * @param  {string} element selector
   * @return {Boolean} return true if validation passed
   */
  var formValidateAjax = function (element) {
    /** {jQuery object} $elements - jQuery object based on selector */
    var $elements = $(element),
      /** {jQuery object} $element - single jQuery object from found collection */
      $element,
      /**  {String} redirectUrl - url read from DOM attribute in form which is validated by this functionality*/
      redirectUrl = '';

    $elements.each(function () {
      $(this).on('click', async function (event) {
        $element = $(this);
        if ($element.closest('form').length > 0) {
          if (!$element.attr('customPrevent')) {
            event.preventDefault();
          }
          /** {Boolean} passed - store validation result */
          var passed = true,
            /** {jQuery object} $form - reference to jquery form */
            $form = $element.closest('form'),
            /** {String} ajaxUrl - string of ajax url (form action) */
            ajaxUrl = $form.attr('action'),
            /** {String} ajaxType - string of ajax type GET/POST */
            ajaxType = $form.attr('method') || 'GET',
            /** {String} cb - callback function to be called */
            cb = $form.attr('cb'),
            /** {jQuery array} passed - store validation result */
            $elementsToValidate = $element.closest('form').find('.form__input:visible');
          $elementsToValidate.each(function (index) {
            var test = vfde.utilities.validate(this);
            passed = passed && test.passed;
            if (ajaxUrl === '') {
              Log.error('formValidateAjax:form action is missing in ajax form');
              return;
            }
          });

          if (passed) {
            //document.location = redirectUrl;

            if (!!$element.attr('customPrevent')) {
              return true;
            }

            const tokenData = await getTokenAnonym();

            $.ajax({
              url: ajaxUrl,
              type: ajaxType,
              data: $form.serialize(),
              headers: {
                'client-anonym-davis-token': tokenData.token,
                'client-anonym-token-parent-log-id': tokenData.parentLogId
              },
              beforeSend: function (response) {
                var $indicator = $form.find('.change-indicator');
                var $button = $($form.find('input[type="submit"]'));
                $button.hide();
                $indicator.addClass('change-indicator--show');
              },
              success: function (response) {
                if (typeof formValidateAjax[cb] === 'function') {
                  formValidateAjax[cb](response, $form);
                }
              },
              error: function (xhr) {
                Log.error('formValidateAjax:Error with ajax request!', xhr);
              }
            });
          }
          if (!!$element.attr('customPrevent')) {
            event.preventDefault();
          }
        }
      });
    });
  };

  // callback methods can be different on various forms
  // it should have ajax response and form handler that call ajax
  var registerMailCallback = function (response, $form) {
    var $indicator = $form.find('.change-indicator');
    var $button = $($form.find('input[type="submit"]'));

    $indicator.removeClass('mt30 change-indicator--show');
    $button.show();

    if (response.error) {
      $form.find('.form__element').addClass('validation--error');
      $form.find('.form__errorText').html(response.error);
    } else {
      var elToFind = $form.find('input[name="formClass"]').val();
      $form.find(elToFind).html();
      $form
        .find(elToFind)
        .html(
          '<div class="mt20 mb10"><h2 class="h1">' + response.message + '</h2></div>'
        );
    }
  };
  formValidateAjax.registerMailCallback = registerMailCallback;

  vfde.utilities.registerUtility(formValidateAjax, 'formValidateAjax');
})(window.vfde);
