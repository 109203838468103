(function (vfde) {
  /**
   * consumptionMobileControllerEnergy: functionality for consumption (electricity) buttons on mobile (reacts also on desktop changes) and adds "kWh/Jahr" String to consumption value
   * @param pContainer {String}: container for consumption buttons on mobile
   * @param pInputSelector {String}: consumption input field
   *
   * Used for slim consumptionCalculator (Electricity)
   */
  const dictionary = require('../utilities/SignsWidthDictionary').default;

  function consumptionMobileControllerEnergy(pContainer, pInputSelector) {
    var _that = this;
    this.$container = pContainer;
    this.separateDots = this.$container.attr('data-separate-digits') || false;
    if (this.separateDots) {
      this.range = [
        { val: '1.500', idx: 1, persons: 1, selector: 'person1' },
        { val: '2.500', idx: 2, persons: 2, selector: 'person2' },
        { val: '3.000', idx: 3, persons: 3, selector: 'person3' },
        { val: '4.000', idx: 4, persons: 4, selector: 'person4' },
        { val: '5.000', idx: 4, persons: 5, selector: 'person4' },
        { val: '7.000', idx: 4, persons: 6, selector: 'person4' }
      ];
    } else {
      this.range = [
        { val: 1500, idx: 1, persons: 1, selector: 'person1' },
        { val: 2500, idx: 2, persons: 2, selector: 'person2' },
        { val: 3000, idx: 3, persons: 3, selector: 'person3' },
        { val: 4000, idx: 4, persons: 4, selector: 'person4' },
        { val: 5000, idx: 4, persons: 5, selector: 'person4' },
        { val: 7000, idx: 4, persons: 6, selector: 'person4' }
      ];
    }

    if (typeof pInputSelector === 'undefined') {
      pInputSelector = '#input__usage--energy';
    }
    this.$consumptionInput = $(pInputSelector);
    if (!this.$consumptionInput.parent().is('span')) {
      _that.$consumptionInput.wrap(
        '<span class="twitter-typeahead twitter-typeahead-spacing"></span>'
      );
    }
    vfde.controllers.register(this, 'consumptionMobileControllerEnergy');

    // init proper type and listen to PubSub
    vfde.libs.PubSub.subscribe(
      'inputSet',
      vfde.controllers.consumptionMobileControllerEnergy.reactOnDesktopChange
    );
    // also we need to listen on topic from inputOverlay to catch all options from dropdown list
    vfde.libs.PubSub.subscribe(
      'inputOverlay',
      vfde.controllers.consumptionMobileControllerEnergy.reactOnDesktopChange
    );

    //also react on direct change
    this.$consumptionInput.on('keyup', function () {
      vfde.controllers.consumptionMobileControllerEnergy.reactOnDesktopChange(
        {},
        {
          newValue: vfde.controllers.consumptionMobileControllerEnergy.$consumptionInput.val()
        }
      );
      _that.shiftDataContent();
    });

    // Update consumption value and highlight mobile consumption button on S2 page
    $(window).on('load', function () {
      vfde.controllers.consumptionMobileControllerEnergy.reactOnDesktopChange(
        {},
        {
          newValue: vfde.controllers.consumptionMobileControllerEnergy.$consumptionInput.val()
        }
      );
      _that.shiftDataContent();
    });
  }

  consumptionMobileControllerEnergy.prototype.shiftDataContent = function () {
    const _that = this;

    if (_that.$consumptionInput.val() == '') {
      _that.$consumptionInput.parent().attr('data-content', '');
      return;
    }

    const signs = _that.$consumptionInput.val().split('');
    let typeaheadLength = 17; // default spacing

    signs.forEach(sign => {
      typeaheadLength += dictionary[sign];
    });

    // add proper class
    _that.$consumptionInput.parent().attr('data-content', 'kWh/Jahr');
    _that.$consumptionInput
      .parent()
      .attr('style', `--typeahead-length: ${typeaheadLength.toFixed(2)}px`);
  };

  consumptionMobileControllerEnergy.prototype.reactOnDesktopChange = function (
    msg,
    data
  ) {
    //var _that = this;
    //pubsub will have different contex!
    var _that = vfde.controllers.consumptionMobileControllerEnergy;
    var foundInRange = false;
    var numPersons = null;
    var rangeLength = _that.range.length;
    var _removeDots = function (val) {
      return val.toString().replace(/\./g, '');
    };

    // as old behaviour that toggleOverlay can send rubbish just protect against it
    if (typeof data.newValue === 'undefined') {
      if (data.action === 'close') {
        _that.$container.find('div').removeClass('isActive');
      }
      _that.shiftDataContent();
      return;
    }

    data.newValue = _removeDots(data.newValue);
    data.newValue = parseInt(data.newValue, 10);

    _that.$container.find('div').removeClass('isActive');
    for (var i = 1; i < _that.range.length; i++) {
      var prev = i - 1;
      if (
        data.newValue < _removeDots(_that.range[i].val) &&
        data.newValue >= _removeDots(_that.range[prev].val)
      ) {
        _that.$container
          .find('div:nth-child(' + _that.range[prev].idx + ')')
          .addClass('isActive');
        numPersons = _that.range[prev].persons;
        foundInRange = true;
      }

      // consider last value (7000)
      if (data.newValue === _removeDots(_that.range[rangeLength - 1].val)) {
        numPersons = _that.range[i].persons;
        _that.$container.find('div:last-child').addClass('isActive');
        foundInRange = true;
      }
    }
    // we finish loop and if not found than it means is out of range if above then mark last one
    if (
      !foundInRange &&
      data.newValue > _removeDots(_that.range[rangeLength - 1].val)
    ) {
      _that.$container.find('div:last-child').addClass('isActive');
    }

    // update NumberOfPersons
    vfde.controllers.consumptionMobileControllerEnergy.setNumberOfPersons(numPersons);

    _that.shiftDataContent();
  };

  consumptionMobileControllerEnergy.prototype.setNumberOfPersons = function (
    numPersons
  ) {
    var _that = vfde.controllers.consumptionMobileControllerEnergy;
    var $numberContainer = _that.$container.find('div:last-child span');

    if (numPersons > 3) {
      $numberContainer.show();
    } else {
      $numberContainer.hide();
    }

    $numberContainer[0].innerText = numPersons;
  };

  consumptionMobileControllerEnergy.prototype.setValue = function (index, $element) {
    var _that = this;
    //increase idx by one
    index++;
    // inner helper
    var _getValue = function (index, currentValue) {
      var newValue = null;
      var numPersons = null;
      var valuesForGivenIdx = [];
      for (var i = 0; i < _that.range.length; i++) {
        if (_that.range[i].idx == index) {
          valuesForGivenIdx.push(_that.range[i]);
        }
      }
      if (valuesForGivenIdx.length === 1) {
        // Button 1-3 clicked
        newValue = valuesForGivenIdx[0].val;
        numPersons = valuesForGivenIdx[0].persons;
      } else {
        // Last Button clicked
        newValue = valuesForGivenIdx[0].val;
        numPersons = valuesForGivenIdx[0].persons;
        for (var i = 1; i < valuesForGivenIdx.length; i++) {
          if (
            _that.$consumptionInput.val() < valuesForGivenIdx[i].val &&
            _that.$consumptionInput.val() >= valuesForGivenIdx[i - 1].val
          ) {
            newValue = valuesForGivenIdx[i].val;
            numPersons = valuesForGivenIdx[i].persons;
            break;
          }
        }
      }

      // update NumberOfPersons
      vfde.controllers.consumptionMobileControllerEnergy.setNumberOfPersons(numPersons);

      // update value
      _that.$consumptionInput.val(newValue).trigger('change');
      _that.shiftDataContent();
    };
    _that.$container.find('div').removeClass('isActive');
    $element.addClass('isActive');
    _getValue(index, _that.$consumptionInput.val());
  };

  // Initialization on query selector so it will be done after jquery loaded but before behaviour initialization
  $('[data-js-controller="consumptionMobileControllerEnergy"]').each(function (index) {
    /** {Object jQuery} - current slider element */
    var $mobileControllerContainer = $(this);
    var config = {};
    // 1. Trying read DOM if failed then default linear configuration
    try {
      var configString = $mobileControllerContainer.attr('config');
      config = JSON.parse(configString);
    } catch (error) {
      config = {
        type: 'energy'
      };
    }
    new consumptionMobileControllerEnergy($mobileControllerContainer);
    //attach on click events to all childs
    var $childs = $mobileControllerContainer.find('div');
    $childs.each(function () {
      //$(this).click = vfde.controllers.consumptionMobileControllerEnergy.setValue(index++, $(this));
      $(this).on('click', function () {
        vfde.controllers.consumptionMobileControllerEnergy.setValue(
          $(this).index(),
          $(this)
        );
      });
    });
  });
})(window.vfde);
