let faqsCounter = 0;

const faqList = {
  '@context': 'https://schema.org',
  '@type': 'FAQPage',
  mainEntity: []
};

const stripHtml = element => {
  const text = element.innerText || element.textContent || '';

  return text.replace(/(?:\r\n|\r|\n)/g, '').replace(/\s\s+/g, ' '); // remove new lines and multiple spaces
};

const addSchemaScript = () => {
  const script = document.createElement('script');
  script.type = 'application/ld+json';
  script.innerHTML = JSON.stringify(faqList);
  document.getElementsByTagName('head')[0].appendChild(script);
};

const addToggleItem = (question, answer) => {
  if (question && answer) {
    faqList.mainEntity.push({
      '@type': 'Question',
      name: question,
      acceptedAnswer: {
        '@type': 'Answer',
        text: answer
      }
    });
  }
};

const startParsing = (cssClass, event = null) => {
  if (!event || event.target.readyState === 'complete') {
    // check for globalFAQ components
    const faqsExistsNumber = document.querySelectorAll('.globalfaq-app').length;

    // listen to event when FAQs was async loaded and ready to be parsed
    if (faqsExistsNumber) {
      const faqsContainer = document.querySelector('.faq');

      faqsContainer.addEventListener('faqsLoaded', event => {
        faqsCounter++;

        event.detail.forEach(i => {
          if (i.enableSchema === 'true') {
            const { answer, question } = i;
            addToggleItem(question, answer);
          }
        });

        // add prepared script to DOM
        if (faqsCounter === faqsExistsNumber) {
          addSchemaScript(faqList);
        }
      });
    }

    // parse all instances of C038 Acordeon components (data from hippo)
    const items = document.querySelectorAll(`.${cssClass}`);
    items.forEach(i => {
      const toggleData = JSON.parse(i.getAttribute('data-toggle'));

      if (!toggleData || toggleData.enableSchema) {
        const question = i.querySelector('.toggleHandle');
        const answer = i.querySelector('.toggle__content');

        addToggleItem(stripHtml(question), stripHtml(answer));
      }
    });

    if (faqsExistsNumber === 0 && items.length && faqList.mainEntity.length) {
      addSchemaScript(faqList);
    }
  }
};

const parseToggleItems = cssClass => {
  if (document.readyState !== 'complete') {
    document.addEventListener('readystatechange', event => {
      startParsing(cssClass, event);
    });
  } else {
    startParsing(cssClass);
  }
};

export { parseToggleItems };
