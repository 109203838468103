/**
 * promotionTracking read data-attributes (data-track) and push to datalayer mainly on click events
 */
import { Log } from './Logger';

const promotionObject = {
  event: 'promo_click',
  ecommerceData: {
    ecommerce: {
      promoClick: {
        promotions: []
      }
    }
  }
};

function _pushToDataLayer(data) {
  if ('dataLayer' in window && typeof dataLayer.push === 'function') {
    Log.debug('promotionTracking: sending such data to dataLayer', data);
    dataLayer.push(data);
  } else {
    Log.error('dataLayer is not defined or dataLayer.push is not a function');
  }
}

export function promotionTracking(wrapper) {
  Log.debug('promotionTracking: Initializing....');
  const DATA_TRACK_ATTR = '[data-track]:not([data-track=""])';

  const trackingElements = wrapper
    ? wrapper.querySelectorAll(DATA_TRACK_ATTR)
    : document.querySelectorAll(DATA_TRACK_ATTR);

  Log.log('promotionTracking: ...tracking elements: ', trackingElements);

  if (trackingElements.length === 0) {
    return;
  }

  trackingElements.forEach((el, i) => {
    el.addEventListener('click', () => {
      let promotion = {};
      let obj = el.getAttribute('data-track');

      try {
        obj = JSON.parse(obj);
      } catch (e) {
        obj = {};
      }

      for (const key in obj) {
        promotion[key] = obj[key];
      }
      //remove from ecommerce tracking json pipes and brackets value to keep clean data
      if (promotion.name) {
        const indexOfPipe = promotion.name.indexOf('|');
        const indexOfBracket = promotion.name.indexOf('(');
        if (indexOfPipe > 0) {
          promotion.name = promotion.name.substr(0, indexOfPipe);
        }
        if (indexOfBracket > 0) {
          promotion.name = promotion.name.substr(0, indexOfBracket);
        }
      }

      Log.debug(
        'promotionTracking: Setting promotion tracking push to dataLayer on click for element:',
        el
      );
      Log.debug('promotionTracking: ...with object:', promotion);

      if (Object.keys(promotion).length === 0) {
        return true;
      }

      promotionObject.ecommerceData.ecommerce.promoClick.promotions[0] = promotion;
      _pushToDataLayer(promotionObject);
      Log.debug(
        'promotionTracking: There was datalayer pushed with object:',
        promotionObject
      );
    });
  });
}

export default promotionTracking;
