import { prepareDavisToken } from './account';

const DI_HEALTH_CHECK = 'davisMaintenanceCheck';

const wrongStatuses = [
  'IN_MAINTENANCE',
  'USER_ERROR',
  'SYSTEM_ERROR',
  'BUSINESS_ERROR',
  'ACCESS_DENIED',
  'NOT_IMPLEMENTED',
  'LOGIN_ERROR',
  'CLIENT_ERROR',
  'WARTUNGSMODUS'
];
const healthCheck = async (parentLogId = null) => {
  prepareDavisToken(parentLogId, 'healthCheck')
    .then(res => {
      const isMaintenanceOrError = wrongStatuses.includes(res.StatusCode);
      sessionStorage.setItem(DI_HEALTH_CHECK, isMaintenanceOrError);
      return isMaintenanceOrError;
    })
    .catch(e => {
      Log.error('error', e);
      sessionStorage.setItem(DI_HEALTH_CHECK, true);
      return false;
    });
};

export { healthCheck, DI_HEALTH_CHECK };
