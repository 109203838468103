import { getGUID } from '../utilities/guid';
import { PubSub } from 'pubsub-js';
import { vfde } from '../vfde';

export const visible = (element, config) => {
  const me = getGUID();
  const { topic, type, initValue = 'show' } = config;
  /** group1 */
  const group = config.group.split(' ');
  const elementType = element.getAttribute('type');

  function publish(par) {
    const grp = par.group || group;
    for (let g in grp) {
      const value = {
        group: grp[g],
        type: par.type || type
      };
      PubSub.publish(topic, { me: me, newValue: value });
    }
  }

  if (initValue === 'hide') {
    element.closest('.form__element').classList.add('hidden');
  }

  if (typeof config.event === 'string') {
    element.addEventListener(config.event, () => {
      if (elementType === 'checkbox' || elementType === 'radio') {
        // checking if checkbox is checked
        if (element.checked === true) {
          //publish({type: 'show'});
          publish({});
        } else {
          //publish({type: 'hide'});
          publish({});
        }
      } else {
        if (config.triggerShow && element.value === config.triggerShow) {
          publish({ type: 'show' });
        }
        if (config.triggerHide && element.value === config.triggerHide) {
          publish({ type: 'hide' });
        }
      }
    });
  } else {
    PubSub.subscribe(topic, (msg, data) => {
      if (data.me === me) {
        return;
      }
      if (data.newValue.group === config.group) {
        // I am in interested group so should hide or show
        if (data.newValue.type === 'show') {
          element.closest('.form__element').classList.remove('hidden');
        }
        if (data.newValue.type === 'hide') {
          element.closest('.form__element').classList.add('hidden');
        }
      }
    });
  }
};

export default visible;

vfde.behaviours.register(visible, 'input.visible');
