const controllersToLoad = [
  {
    selectors: ['[data-js-controller="heroController"]'],
    handler: 'heroController'
  },
  {
    selectors: ['[data-js-controller="CheckStatusPublicSwitchController"]'],
    handler: 'checkStatusPublicSwitchController'
  },
  {
    selectors: ['[data-js-controller="SliderController"]'],
    handler: 'sliderController'
  },
  {
    selectors: ['[data-js-controller="navigation"]'],
    handler: 'navigationController'
  },
  {
    selectors: ['[data-js-controller="CheckStatusController"]'],
    handler: 'checkStatusController'
  },
  {
    selectors: ['[data-js-controller="consumptionMobileControllerEnergy"]'],
    handler: 'consumptionMobileControllerEnergy'
  },
  {
    selectors: ['[data-js-controller="consumptionMobileControllerGas"]'],
    handler: 'consumptionMobileControllerGas'
  },
  {
    selectors: ['[data-download]'],
    handler: 'dataDownloadController'
  },
  {
    selectors: ['.table-restructure'],
    handler: 'tableController'
  },
  {
    selectors: ['[data-js-controller="warning"]'],
    handler: 'warningController'
  }
];

export { controllersToLoad };
