import { Container } from '../behaviours/Container';

const SELECTOR = '.content-slideshow';

export function contentSlideShow() {
  const containersArray = document.querySelectorAll(SELECTOR);

  [].forEach.call(containersArray, el => {
    new Container(el);
  });
}
