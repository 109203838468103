/**
 * This file handles a legacy `gulp handlebars` task.
 * I.E. it loads clientTemplates/** files, compiles them
 * and puts into a global window.app namespace
 *
 * TODO: import all templates directly from files that need them
 */
import globals from './globals';

import dummy from '../components/clienttemplates/dummy.hbs';
import footnote from '../components/clienttemplates/footnote.hbs';
import loginbox from '../components/clienttemplates/loginbox.hbs';
import optin from '../components/clienttemplates/optin.hbs';
import personalDataForm from '../components/clienttemplates/personal-data-form.hbs';
import spinner from '../components/clienttemplates/spinner.hbs';
import tarrifMessage from '../components/clienttemplates/tariffMessage.hbs';

const app = {};

const templates = [
  { name: 'dummy', template: dummy },
  { name: 'footnote', template: footnote },
  { name: 'loginbox', template: loginbox },
  { name: 'optin', template: optin },
  { name: 'personal-data-form', template: personalDataForm },
  { name: 'spinner', template: spinner },
  { name: 'tariffMessage', template: tarrifMessage }
];

templates.forEach(item => {
  app[item.name] = item.template;
});

globals.window.app = app;
