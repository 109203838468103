export class Logger {
  /* PROPERTIES */
  get logLevel() {
    return this._logLevel;
  }

  set logLevel(pLogLevel) {
    if (pLogLevel === 'false') {
      // no debug chosen in localstorage
      this._logLevel = {
        name: 'OFF',
        value: 10
      };
    } else {
      for (let option in this.config) {
        if (option === pLogLevel) {
          this._logLevel = {
            name: option,
            value: this.config[option]
          };
          return;
        }
      }
      // value not found in options so turn off logging to be on safe side
      this._logLevel = {
        name: 'OFF',
        value: 10
      };
    }
  }

  /* METHODS */
  constructor() {
    /* */
    let _this = this,
      debug = 'false';
    this.config = {
      DEBUG: 1,
      INFO: 2,
      LOG: 3,
      WARN: 4,
      ERROR: 5,
      OFF: 10
    };
    try {
      debug = localStorage.getItem('debug') || 'false';
    } catch (e) {
      // it seems we should not care as fallback below if localStorage fail in some private mode or sth...
    }
    // we are forcing setter call
    this.logLevel = debug;
    // if high level of log then performance measurement
    if (this._logLevel.value <= 2) {
      $(window).on('load', function () {
        setTimeout(function () {
          _this.getPerformance();
        }, 17);
      });
    }
  }

  // TODO: move `getPerformance` out of Logger class (maybe to `init/*`?)
  getPerformance() {
    // performance measurements
    //1. JS time load (include CSS and image so compare with profile vfde_js)
    // removed featues.js
    //window.performance.measure('measure_js_time', 'vfde_js_start', 'vfde_js_end');
    //2. network latency
    window.performance.measure('measure_network_latency', 'fetchStart', 'responseEnd');
    //3. page load
    window.performance.measure('measure_page_load', 'responseEnd', 'loadEventEnd');
    //4. total time
    window.performance.measure('measure_total_time', 'navigationStart', 'loadEventEnd');
    if (console.table) {
      console.table(window.performance.getEntriesByType('Measure'));
    }
  }

  getParams(params) {
    let args = [];
    for (let i = 0; i < params.length; i++) {
      args.push(params[i]);
    }
    return args.join();
  }

  debug() {
    if (this.logLevel.value <= this.config.DEBUG) {
      console.debug.apply(console, arguments);
    }
  }

  info() {
    if (this.logLevel.value <= this.config.INFO) {
      console.info.apply(console, arguments);
    }
  }

  log() {
    if (this.logLevel.value <= this.config.LOG) {
      console.log.apply(console, arguments);
    }
  }

  warn() {
    if (this.logLevel.value <= this.config.WARN) {
      console.warn.apply(console, arguments);
    }
  }

  error() {
    if (this.logLevel.value <= this.config.ERROR) {
      console.error.apply(console, arguments);
    }
  }
}

export const Log = new Logger();
