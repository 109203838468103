import { vfde } from '../../../vfde';
import { generateUUID } from '../../helpers/guid';
import { headersCommon, getDefaultRequest } from '../../helpers/config';

const TOKEN_ID = 'tokenAnonym';
const TOKEN_TYPE = ['access', 'accessQS'];
const DI_SETTINGS_KEY = 'davisSettings';
const DI_SETTINGS_NEW_VERSION = '1.0.0';

let davisConfig = {};

const getDavisConfig = () => {
  if (Object.keys(davisConfig).length) {
    return Promise.resolve(davisConfig);
  }

  const settings = localStorage.getItem(DI_SETTINGS_KEY);

  if (settings) {
    const parsedSettings = JSON.parse(settings);

    if (parsedSettings.version === DI_SETTINGS_NEW_VERSION) {
      return Promise.resolve(parsedSettings);
    }
  }

  return fetch(`${vfde.constants.DAVIS_CONFIG_URL}/davisSettings.json`)
    .then(res => res.json())
    .then(data => {
      localStorage.setItem(DI_SETTINGS_KEY, JSON.stringify(data));
      return data;
    })
    .catch(err => {
      console.log(err);
    });
};

const getTokenAnonym = async () => {
  const storagedToken = sessionStorage.getItem(TOKEN_ID);

  if (storagedToken) {
    // check if expired using saved created variable when token aquired
    const now = Date.now();
    const existingTokenData = JSON.parse(storagedToken);
    const expiredIn10Min = existingTokenData.created + 550000; // add almost 10min
    const isTokenValid = validateTokenAnonym(existingTokenData.token);
    const isTokenExpired = now >= expiredIn10Min;

    if (!isTokenValid) {
      return await requestToken();
    }

    if (!isTokenExpired) {
      return Promise.resolve(existingTokenData);
    } else {
      return await requestToken(existingTokenData.parentLogId);
    }
  }
  return await requestToken();
};

const initTokenAnonym = async () => {
  davisConfig = await getDavisConfig();
  await getTokenAnonym();
};
// ---------------------------

const prepareDavisToken = async (parentLogId = null, referenzId = null) => {
  if (!Object.keys(davisConfig).length) {
    davisConfig = await getDavisConfig();
  }

  const headers = {
    ...headersCommon,
    'Ocp-Apim-Subscription-Key': davisConfig.subscriptionKey
  };

  const guid = generateUUID();
  const body = {
    ...getDefaultRequest(davisConfig, { parentLogId }, guid),
    ReferenzId: referenzId ? referenzId : 'optin',
    Priority: null,
    RequestKeyValues: {},
    Request: {
      Tenant: null,
      Werte: {}
    }
  };
  return fetch(
    `${davisConfig.serverPath}${davisConfig.newProductPath}Account/GetTokenAnonym`,
    {
      method: 'POST',
      headers,
      body: JSON.stringify(body)
    }
  ).then(response => response.json());
};

const requestToken = async (parentLogId = null) => {
  return prepareDavisToken(parentLogId)
    .then(res => {
      const guid = generateUUID();
      const tokenData = {
        token: res.Result.AccessToken,
        refreshToken: res.Result.RefreshToken,
        parentLogId: res.ParentLogId,
        logId: res.LogId,
        transactionId: guid,
        created: Date.now()
      };
      sessionStorage.setItem(TOKEN_ID, JSON.stringify(tokenData));
      return tokenData;
    })
    .catch(e => {
      Log.error('error', e);
    });
};

const validateTokenAnonym = token => {
  if (!token) return false;

  const type = token.split('.')[0];
  return TOKEN_TYPE.includes(type);
};

export { TOKEN_ID, getDavisConfig, initTokenAnonym, getTokenAnonym, prepareDavisToken };
