import $ from 'jquery';

import { Log } from '../utilities/Logger';
import { window } from '../globals';

const ATTRIBUTE = 'data-map-config';
const SELECTOR = `[${ATTRIBUTE}]`;

export function maps() {
  const $mapsElement = $(SELECTOR);

  if ($mapsElement.length > 0) {
    // add global callback if map found
    window.initMap = function () {
      let mapConfig = $mapsElement.attr(ATTRIBUTE);

      try {
        mapConfig = JSON.parse(mapConfig);
      } catch (error) {
        Log.error('Error parsing map config', error);
        Log.error('Error parsing map config for configuration', mapConfig);
        return;
      }

      const map = new google.maps.Map($mapsElement[0], {
        center: {
          lat: parseFloat(mapConfig.map.lat),
          lng: parseFloat(mapConfig.map.lng)
        },
        zoom: parseInt(mapConfig.map.zoom, 10) || 10
      });

      mapConfig.markers.forEach(m => {
        const infoWindow = new google.maps.InfoWindow();
        const marker = new google.maps.Marker({
          position: {
            lat: parseFloat(m.lat),
            lng: parseFloat(m.lng)
          },
          map: map
        });
        infoWindow.setContent(m.info);
        infoWindow.open(map, marker);
      });
    };

    // get external google map APIs
    $.getScript(
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyDNFqrN87qi0-ejne7vH-N8qDwOmLVk7vw&callback=initMap'
    );
  }
}
