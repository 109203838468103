import { document } from '../globals';
import { Log } from '../utilities/Logger';
import { isRequired, TYPES } from './types';

export function dispatchGlobalEvent(eventName = isRequired('eventName', TYPES.STR)) {
  let event;

  if (document.createEvent) {
    event = document.createEvent('Event');
    event.initEvent(eventName, true, true);
    document.dispatchEvent(event);
  } else {
    event = document.attachEvent('Event');
    document.documentElement[eventName]++;
  }

  Log.log(`A global event is executed with name: ${eventName}`);

  return event; // used only for unit tests purposes
}
