/**
 * Dictionary for signs width (font-size: 19px)
 * @return {Object}
 **/
const dictionary = {
  1: 7.8,
  2: 13,
  3: 13.2,
  4: 13.5,
  5: 13,
  6: 13.2,
  7: 12,
  8: 13.8,
  9: 13,
  0: 14,
  '.': 3.3,
  ',': 3.3
};

export default dictionary;
