var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            \""
    + alias1(((helper = (helper = lookupProperty(helpers,"key") || (data && lookupProperty(data,"key"))) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"key","hash":{},"data":data,"loc":{"start":{"line":12,"column":13},"end":{"line":12,"column":21}}}) : helper)))
    + "\": \""
    + alias1(container.lambda(depth0, depth0))
    + "\"\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <input name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"key") || (data && lookupProperty(data,"key"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":20,"column":27},"end":{"line":20,"column":35}}}) : helper)))
    + "\" type=\"hidden\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"value") || (depth0 != null ? lookupProperty(depth0,"value") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"value","hash":{},"data":data,"loc":{"start":{"line":20,"column":58},"end":{"line":20,"column":67}}}) : helper)))
    + "\">\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"welcomeText") || (depth0 != null ? lookupProperty(depth0,"welcomeText") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"welcomeText","hash":{},"data":data,"loc":{"start":{"line":27,"column":20},"end":{"line":27,"column":37}}}) : helper))) != null ? stack1 : "")
    + "\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "                    Melden Sie sich bitte mit Ihrem Vattenfall Zugang an um Ihren Gutschein freizuschalten.\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"password") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(12, data, 0),"data":data,"loc":{"start":{"line":34,"column":16},"end":{"line":106,"column":23}}})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"form__element\">\n                  <label class=\"form__element__label\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"label","hash":{},"data":data,"loc":{"start":{"line":36,"column":54},"end":{"line":36,"column":63}}}) : helper)))
    + "</label>\n                  <div class=\"form__control\">\n                    <input class=\"form__input\" type=\"password\"\n                      name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"key") || (data && lookupProperty(data,"key"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":39,"column":28},"end":{"line":39,"column":36}}}) : helper)))
    + "\"\n                      placeholder=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"placeholder") || (depth0 != null ? lookupProperty(depth0,"placeholder") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"placeholder","hash":{},"data":data,"loc":{"start":{"line":40,"column":35},"end":{"line":40,"column":50}}}) : helper)))
    + "\"\n                      value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"value") || (depth0 != null ? lookupProperty(depth0,"value") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"value","hash":{},"data":data,"loc":{"start":{"line":41,"column":29},"end":{"line":41,"column":38}}}) : helper)))
    + "\"\n                      data-reactive='{\n                        \"force.validate\": {\n                          \"event\" : \"change blur input\"\n                        }\n                      }'\n                      data-sanitize='{\n                        \"rules\": {\n                          \"allowedRegex\": {\n                            \"regex\": \".*\"\n                          },\n                          \"maxlength\": {\n                            \"max\": 99\n                          }\n                        }\n                      }'\n                      data-validate='{\n                        \"rules\": {\n                          \"required\": {\n                            \"msg\": \"Bitte geben Sie Ihr Passwort ein\"\n                          }\n                        }\n                      }'/>\n                  </div>\n                  <div class=\"form__text\">\n                    <p class=\"form__errorText\">\n                    </p>\n                  </div>\n                </div>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"form__element\">\n                  <label class=\"form__element__label\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"label","hash":{},"data":data,"loc":{"start":{"line":72,"column":54},"end":{"line":72,"column":63}}}) : helper)))
    + "</label>\n                  <div class=\"form__control\">\n                    <input class=\"form__input\" type=\"text\"\n                      name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"key") || (data && lookupProperty(data,"key"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":75,"column":28},"end":{"line":75,"column":36}}}) : helper)))
    + "\"\n                      placeholder=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"placeholder") || (depth0 != null ? lookupProperty(depth0,"placeholder") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"placeholder","hash":{},"data":data,"loc":{"start":{"line":76,"column":35},"end":{"line":76,"column":50}}}) : helper)))
    + "\"\n                      value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"value") || (depth0 != null ? lookupProperty(depth0,"value") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"value","hash":{},"data":data,"loc":{"start":{"line":77,"column":29},"end":{"line":77,"column":38}}}) : helper)))
    + "\"\n                      data-reactive='{\n                        \"force.validate\": {\n                          \"event\" : \"change blur input\"\n                        }\n                      }'\n                      data-sanitize='{\n                        \"rules\": {\n                          \"allowedRegex\": {\n                            \"regex\": \".*\"\n                          },\n                          \"maxlength\": {\n                            \"max\": 99\n                          }\n                        }\n                      }'\n                      data-validate='{\n                        \"rules\": {\n                          \"required\": {\n                            \"msg\": \"Bitte geben Sie Ihren Benutzernamen ein\"\n                          }\n                        }\n                      }'\n                    />\n                  </div>\n                  <div class=\"form__text\">\n                    <p class=\"form__errorText\"></p>\n                  </div>\n                </div>\n";
},"14":function(container,depth0,helpers,partials,data) {
    return " login-error";
},"16":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li><a href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"href") || (depth0 != null ? lookupProperty(depth0,"href") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"href","hash":{},"data":data,"loc":{"start":{"line":113,"column":29},"end":{"line":113,"column":37}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"label","hash":{},"data":data,"loc":{"start":{"line":113,"column":39},"end":{"line":113,"column":48}}}) : helper)))
    + "</a></li>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"buttonLabel") || (depth0 != null ? lookupProperty(depth0,"buttonLabel") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"buttonLabel","hash":{},"data":data,"loc":{"start":{"line":134,"column":16},"end":{"line":134,"column":33}}}) : helper))) != null ? stack1 : "")
    + "\n";
},"20":function(container,depth0,helpers,partials,data) {
    return "                Gutschein anzeigen\n";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"optinBox") : depth0),{"name":"with","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":145,"column":8},"end":{"line":161,"column":17}}})) != null ? stack1 : "");
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"row mr0 ml0 pt20 pb6 "
    + alias4(((helper = (helper = lookupProperty(helpers,"background") || (depth0 != null ? lookupProperty(depth0,"background") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"background","hash":{},"data":data,"loc":{"start":{"line":146,"column":45},"end":{"line":146,"column":59}}}) : helper)))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"color") || (depth0 != null ? lookupProperty(depth0,"color") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"color","hash":{},"data":data,"loc":{"start":{"line":146,"column":60},"end":{"line":146,"column":69}}}) : helper)))
    + " voucher-register-box\"\n                 data-pubSubRegister='{\n                   \"selector\": \"a.modalOpen\",\n                   \"event\": \"click\",\n                   \"topic\": \""
    + alias4(((helper = (helper = lookupProperty(helpers,"topic") || (depth0 != null ? lookupProperty(depth0,"topic") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"topic","hash":{},"data":data,"loc":{"start":{"line":150,"column":29},"end":{"line":150,"column":38}}}) : helper)))
    + "\",\n                   \"data\": {}\n                 }'>\n              <div class=\"col-sm-10 col-sm-offset-1\">\n                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"text_headline") : depth0),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":154,"column":16},"end":{"line":154,"column":97}}})) != null ? stack1 : "")
    + "\n                <p class=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"text_alignment") || (depth0 != null ? lookupProperty(depth0,"text_alignment") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"text_alignment","hash":{},"data":data,"loc":{"start":{"line":155,"column":26},"end":{"line":155,"column":44}}}) : helper)))
    + "\">\n                  "
    + alias4(((helper = (helper = lookupProperty(helpers,"text_subline") || (depth0 != null ? lookupProperty(depth0,"text_subline") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"text_subline","hash":{},"data":data,"loc":{"start":{"line":156,"column":18},"end":{"line":156,"column":34}}}) : helper)))
    + "\n                  "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"text_link_title") : depth0),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":157,"column":18},"end":{"line":157,"column":101}}})) != null ? stack1 : "")
    + "\n                </p>\n              </div>\n            </div>\n";
},"24":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h3 class=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"text_alignment") || (depth0 != null ? lookupProperty(depth0,"text_alignment") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"text_alignment","hash":{},"data":data,"loc":{"start":{"line":154,"column":48},"end":{"line":154,"column":66}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"text_headline") || (depth0 != null ? lookupProperty(depth0,"text_headline") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"text_headline","hash":{},"data":data,"loc":{"start":{"line":154,"column":68},"end":{"line":154,"column":85}}}) : helper)))
    + "</h3>";
},"26":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a href=\"#\" class=\"modalOpen\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"text_link_title") || (depth0 != null ? lookupProperty(depth0,"text_link_title") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"text_link_title","hash":{},"data":data,"loc":{"start":{"line":157,"column":71},"end":{"line":157,"column":90}}}) : helper)))
    + "</a>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal fade\" id=\"modalLoginBox\" tabindex=\"-1\" role=\"dialog\" aria-labelledby=\"myModalLabel\">\n  <div class=\"modal-dialog modal-md\" role=\"document\">\n    <a class=\"close color--black\" data-dismiss=\"modal\" aria-label=\"Close\">\n      <i class=\"icon vf-icon vf-icon-close\"></i>\n    </a>\n    <div class=\"modal-content color-bg--grey-fallback\">\n      <!-- modal content -->\n      <form id=\"login\" novalidate class=\"form form--grey consumption-calculator--s1 mb0 no-border\"\n        data-reactive='{\n          \"form.csoLogin\" : {\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"csoLogin") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":11},"end":{"line":13,"column":20}}})) != null ? stack1 : "")
    + "          }\n        }'\n      >\n        <div class=\"row\">\n          <div class=\"col-sm-10 col-sm-offset-1\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"hiddenFields") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":12},"end":{"line":21,"column":21}}})) != null ? stack1 : "")
    + "            <h1 class=\"form__header\">Anmelden</h1>\n            <fieldset class=\"form__section\">\n              <div class=\"form__element\">\n                <div class=\"form__text\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"welcomeText") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":26,"column":18},"end":{"line":30,"column":25}}})) != null ? stack1 : "")
    + "                </div>\n              </div>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"fields") : depth0),{"name":"each","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":14},"end":{"line":107,"column":23}}})) != null ? stack1 : "")
    + "              <div class=\"form__element mt8"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"error") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":108,"column":43},"end":{"line":108,"column":75}}})) != null ? stack1 : "")
    + "\">\n                <div id=\"mainMsgError\" class=\"form__text color--white\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"error") || (depth0 != null ? lookupProperty(depth0,"error") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"error","hash":{},"data":data,"loc":{"start":{"line":109,"column":71},"end":{"line":109,"column":80}}}) : helper)))
    + "</div>\n              </div>\n              <ul class=\"list--links mb0\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"links") : depth0),{"name":"each","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":112,"column":16},"end":{"line":114,"column":25}}})) != null ? stack1 : "")
    + "              </ul>\n            <div class=\"form__element error__message mt16\">\n              <div class=\"form__text\">\n                <p class=\"form__errorText\"></p>\n              </div>\n            </div>\n            </fieldset>\n            <div class=\"form__actions form__actions--overflow-y color--white\">\n              <div class=\"change-indicator change-indicator\">\n                <div class=\"change-indicator__pane\"></div>\n              </div>\n              <button\n                class=\"button button--new-design button-large button-nd--orange button--centered button--loading\"\n                type=\"submit\"\n                customPrevent=\"true\"\n                data-validateFormAjax\n              >\n                <i class=\"icon vf-icon vf-icon-loading-indicator hidden\"></i>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"buttonLabel") : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.program(20, data, 0),"data":data,"loc":{"start":{"line":133,"column":16},"end":{"line":137,"column":23}}})) != null ? stack1 : "")
    + "              </button>\n            </div>\n          </div>\n        </div>\n      </form>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"optinBox") : depth0)) != null ? lookupProperty(stack1,"topic") : stack1),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":144,"column":6},"end":{"line":162,"column":13}}})) != null ? stack1 : "")
    + "      <!-- /modal content -->\n    </div>\n  </div>\n</div>\n";
},"useData":true});