import $ from 'jquery';

import { vfde } from '../vfde';
import { convertTypeNumberToText } from '../utilities/convertTypeNumberToText';

export function formInputs() {
  if (!vfde.isMobile) {
    convertTypeNumberToText(
      '.consumption-calculator--s1 input[type="number"], .consumption-calculator input[type="number"], .lp-slim-calculator input[type="number"]'
    );
  }
}

/**
 * svgFix()
 *
 * Checks if any <svg> elements points to external files.
 * If yes, then it fetches the files via AJAX call and uses it locally on the page.
 *
 * See: https://css-tricks.com/ajaxing-svg-sprite/
 */
export function svgFix(urls = []) {
  const selector = 'svg use';
  const attribute = 'xlink:href';
  const regex = /(^.*\/\/[a-z0-9\-\.\:\/_]+\/)(.+\.svg)(#.+$)/i;

  $(selector).each((i, el) => {
    const $el = $(el);
    const link = $el.attr(attribute);

    if (regex.test(link)) {
      const [, url, file, symbol] = link.match(regex);

      if (urls.indexOf(url) < 0) {
        urls.push(url);

        _fetchImage().then(_appendImageToDocument).then(_replacePath);
      } else {
        _replacePath();
      }

      // noinspection JSAnnotator
      function _fetchImage() {
        return $.get(`${url}${file}`);
      }

      // noinspection JSAnnotator
      function _appendImageToDocument(data) {
        const svg = new XMLSerializer().serializeToString(data);
        const $svg = $(svg).addClass('hidden');

        $(document.body).append($svg);

        return $.Deferred().resolve();
      }

      // noinspection JSAnnotator
      function _replacePath() {
        $el.attr(attribute, symbol);
      }
    }
  });

  return urls;
}

vfde.fixes = vfde.fixes || {};
vfde.fixes.svgFix = svgFix;
