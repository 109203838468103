(function (vfde) {
  'use strict';
  /**
   * resizableIframe based on solution from here:
   * https://github.com/davidjbradshaw/iframe-resizer
   * iframeResizer.min.js is included in libs folder
   * iframeResizer.conterntWindow.min.js should
   * be added by external company to their site which will be 'iframed'
   *
   */
  var resizableIframe = function (selector) {
    /** {jQuery object} $elements - jQuery object based on selector */
    var $resizableIframes = $(selector),
      /** {jQuery object} $element - single jQuery object from found collection */
      $iframe;
    if ($resizableIframes.length > 0) {
      $.getScript(vfde.feVersion + 'scripts/libs/iframeResizer.min.js', function () {
        // we got addtional library so we initialize resizableIframe
        $resizableIframes.each(function () {
          $iframe = $(this);
          $iframe.iFrameResize({ log: false, checkOrigin: false });
        });
      });
    }
  };

  window.onmessage = function (event) {
    if (event.data == 'scrolling') {
      const $target = $('iframe[src^="' + event.origin + '"]');
      if ($target.length) {
        $target.get(0).scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
      }
    }
  };

  vfde.utilities.registerUtility(resizableIframe, 'resizableIframe');
})(window.vfde);
