export const TYPES = {
  NUM: 'Number',
  STR: 'String',
  BOOL: 'Boolean',
  OBJ: 'Object',
  ARR: 'Array',
  FN: 'Function',
  ELEM: 'DOM Element',
  CUSTOM: 'Custom Type (check code)',
  A: 'Any Type',
  NO: 'No type specified'
};

export function isRequired(name, type = TYPES.NO) {
  throw new Error(`A required parameter "${name}" (${type}) is missing!`);
}
