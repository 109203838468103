import PubSub from 'pubsub-js';
// import Handlebars from 'handlebars';
import Segment from 'segment-js';
import noUiSlider from 'nouislider';
import { parseJSON } from '../utils/json';

import { window } from '../globals';

import { behaviours } from './behaviours';
import cookies from './cookies';
import debug from './debug';
import feVersion from './feVersion';
import feUri from './feUri';
import inIframe from './inIframe';
import isIE from './isIE';
import isIOS from './isIOS';
import isMobile from './isMobile';
import isEdge from './isEdge';
import isKSO from './isKSO';
import { parseToggleItems } from '../init/schemaOrg';
// import vue from './vue'; // todo: do we really need it?
import {
  componentsToLoadConditionally,
  loadVueComponent
} from '../vueComponentsLoader';

const DAVIS_CONFIG_URL =
  feUri.authority && feUri.authority.includes('localhost')
    ? `${feUri.scheme}://${feUri.authority}/files`
    : `${feUri.scheme}://${feUri.authority}/davis-config`;

export const vfde = {
  cookies,
  debug,
  feVersion,
  feUri,
  inIframe,
  isIE,
  isEdge,
  isIOS,
  isMobile,
  isKSO,
  vueHippo: {
    componentsToLoadConditionally,
    loadVueComponent
  },
  behaviours,
  libs: {
    // TODO: remove after full migration
    PubSub,
    // Handlebars, // seems not to be used at all from here
    segment: Segment,
    nouislider: noUiSlider
  },
  templates: {
    // TODO: remove after all template are imported the ES6 way
    getTemplate(templatename) {
      return window.app[templatename];
    }
  },
  controllers: {
    // TODO: remove after full migration
    register(controllerToAdd, controllerName) {
      this[controllerName] = controllerToAdd;
    }
  },
  services: {
    registerService(serviceToAdd, serviceName) {
      this[serviceName] = serviceToAdd;
      serviceToAdd.init();
    }
  },
  models: {
    registerModel(modelToAdd, modelName) {
      this[modelName] = modelToAdd;
    }
  },
  utilities: {
    registerUtility(utilityToAdd, utilityName) {
      this[utilityName] = utilityToAdd;
    }
  },
  constants: {
    DATALAYER: 'datalayer',
    KSO_HOST: [
      // PRD/ACC
      /^https?:\/\/service(-acc)?.vattenfall.de$/,
      // PRD SLOTS
      /^https?:\/\/vattenfallde-kso-web-prd-slot\d{1}.azurewebsites.net$/,
      // TST
      /^https?:\/\/(fast|furious)-kso-web-tst.azurewebsites.net$/,
      /^https?:\/\/vattenfallde-kso-web-(fragile|freedom|frenzy).azurewebsites.net$/
    ],
    DAVIS_CONFIG_URL,
    LOGIN_URL: 'https://service.vattenfall.de/s/login',
    LOGOUT_URL: 'https://service.vattenfall.de/s/logout',
    LOGOUT_URL_KSO: 'https://service.vattenfall.de/login?logout=',
    GLOBAL_ERROR_MESSAGE: 'Bitte überprüfen Sie Ihre Eingaben.',
    EMAIL_ERROR_MESSAGE: 'Bitte geben Sie eine gültige E-Mail Adresse ein.',
    PHONE_ERROR_MESSAGE: 'Bitte geben Sie eine gültige Telefonnummer ein.',
    FIRSTNAME_ERROR_MESSAGE: 'Bitte geben Sie Ihren Vornamen ohne Sonderzeichen ein.',
    LASTNAME_ERROR_MESSAGE: 'Bitte geben Sie Ihren Nachnamen ohne Sonderzeichen ein.',
    BIRTHDATE_ERROR_MESSAGE:
      'Bitte geben Sie Ihr Geburtsdatum in folgendem Format ein: JJJJ-MM-TT (z.B. 1980-11-20).',
    FALLBACK_ERROR_MESSAGE:
      'Unbekannter Fehler. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.',
    SERVICE_ERROR_MESSAGE:
      'Service ist momentan nicht erreichbar. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.'
  },
  init(selector = '') {
    const promises = [];
    const reactiveComponennts = Array.from(
      new Set(
        Array.from(document.querySelectorAll('[data-reactive]')).reduce(
          (arr, element) => {
            // check if data-reactive object is valid
            const reactiveData = parseJSON(
              element.dataset.reactive,
              'vfde-reactiveComponents-loader'
            );
            if (reactiveData) {
              const keys = Object.keys(reactiveData);
              return arr.concat(keys);
            }

            return arr;
          },
          []
        )
      )
    );

    const legacyComponents = Object.keys(behaviours.legacy);

    reactiveComponennts.forEach(component => {
      if (component === 'vue.runApp') {
        // only for product-direct-order, price-limit-calculator in snippet includer
        promises.push(
          ...[
            import(`../vueComponents`),
            import(`../vueProductsNotCom`),
            import(`../vuePriceLimitCalculator`)
          ]
        );
      }

      if (legacyComponents.includes(component)) {
        promises.push(import(`../behaviours/${behaviours.legacy[component]}`));
      } else {
        promises.push(import(`../behaviours/${component}`));
      }
    });

    componentsToLoadConditionally.forEach(component => {
      loadVueComponent(component, promises);
    });

    Promise.all(Array.from(new Set(promises))).then(() => {
      this.behaviours.load(selector);
      parseToggleItems('accordeon-item');
    });
  }
};

export default vfde;
