import { Log } from '../utilities/Logger';
import { JSON } from '../globals';

export function parseJSON(str, caller) {
  try {
    return JSON.parse(str);
  } catch (err) {
    Log.error(`[${caller}]`, 'invalid config string:', str);
    Log.error(`[${caller}]`, 'error message:', err);
  }
}
