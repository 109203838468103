// fields names to map from
const fields = {
  statusCode: 'StatusCode',
  messages: 'Meldungen',
  message: {
    description: 'FachlicheBeschreibung'
  },
  places: 'Orte',
  place: {
    isBlacklisted: 'IstBlacklist',
    isBordercase: 'IstRandgebiet',
    name: 'Name',
    zipCode: 'PLZ'
  }
};

export { fields };
