(function (vfde) {
  'use strict';
  /**
   * stage.video listen for pub sub to show video
   *
   */

  /** {jQuery Object} video backgroud/mask covering whole page*/
  var $videoMask = null,
    videoUrl = null,
    $outerDiv = null,
    $iframe = null,
    interval = null,
    videoList = {};

  var video = function (element, config) {
    var me = vfde.utilities.getGUID(),
      $element = $(element),
      config = config,
      topic = config.topic;
    videoUrl = config.videoUrl || '';
    videoList[topic] = videoUrl;

    vfde.libs.PubSub.subscribe(topic, function (msg, data) {
      if (data.me === me) return;
      swipeFromLeft(videoList[topic]);
    });
  };

  /**
   * Method to insert video object to video mask/background that was slide from left
   * Call once only when jwplayer was initialized and video from url loaded
   */
  function showVideo(pVideoUrl) {
    if ($iframe.attr('src') !== pVideoUrl) {
      $iframe.attr('src', pVideoUrl);
      vfde.utilities.youtube(0.7);
    }
    // currently commented, we will use this approach if Bastian will want auto play on desktops
    /* else {
      $iframe[0].contentWindow.postMessage('{"event":"command","func":"' + 'playVideo' + '","args":""}', '*');
    }*/
  }

  /**
   * Add css class for slide left animiation
   * if jwplaey not initialized then add close icon and waiting icon
   */
  function swipeFromLeft(pVideoUrl) {
    var $closeIcon, $waitIcon;
    getMask(function () {
      //hide previous video if was loaded
      if ($iframe.attr('src') !== pVideoUrl) {
        $iframe.removeAttr('src');
      }
      $videoMask.addClass('showFromLeft');
      $videoMask.on(
        'webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend',
        function (event) {
          showVideo(pVideoUrl);
        }
      );
    });
  }
  /**
   * Helper that is attached to video background and close icon
   * try to pause video and hide slide left css class
   */
  function hideVideo() {
    $(document).off('keyup');
    clearInterval(interval);
    //clear events on videoMask
    $videoMask.unbind(
      'webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend'
    );
    $iframe[0].contentWindow.postMessage(
      '{"event":"command","func":"' + 'stopVideo' + '","args":""}',
      '*'
    );
    // to avoid cutted animation
    setTimeout(function () {
      $videoMask.removeClass('showFromLeft');
    }, 0);
  }

  /**
   * Small helper to make focusout on iframe, so events from document will work
   */
  function _changeHandler() {
    // clicking fullscreen on YT iframe is causing that document event is not fired
    // making focus back on document makes event working so ESC is hiding video like Bastian wanted
    // if user will want to click on video actions no side effect
    $iframe.blur();
    $(document).focus();
  }

  /**
   * Initialize mask/background for video to cover page and reacts on
   * click (to close)
   */
  function getMask(cb) {
    // check if videoMask is already created if not add to DOM
    if ($videoMask === null) {
      var maskTemplate =
        ' <div id="videoMask">' +
        '   <span class="icon--before--close-white"><i class="icon vf-icon vf-icon-close-white"></i></span>' +
        '   <div class="video-iframe video-iframe--stage">' +
        '    <iframe frameborder="0" allowfullscreen="true" data-youtube-video="true">' +
        '    </iframe>' +
        '   </div>' +
        ' </div>';
      var maskHtml = $.parseHTML(maskTemplate);
      $('body').append(maskHtml);

      //variables
      $videoMask = $('#videoMask');
      $outerDiv = $('#videoMask .video-iframe');
      $iframe = $('#videoMask  iframe');
      var $closeIcon = $('#videoMask .icon--before--close-white');

      // events
      interval = setInterval(_changeHandler, 1000);
      $closeIcon.on('click', function () {
        hideVideo();
      });
    }

    // add events to capture ESC on full screen mode
    document.addEventListener('fullscreenchange', _changeHandler, false);
    document.addEventListener('webkitfullscreenchange', _changeHandler, false);
    document.addEventListener('mozfullscreenchange', _changeHandler, false);

    $videoMask.on('click', function (event) {
      if ($(event.target).attr('id') === 'videoMask') {
        $(this).unbind('click');
        hideVideo();
      }
    });

    $(window).keyup(function (e) {
      if (e.keyCode == 27) {
        // escape key maps to keycode `27`
        hideVideo();
      }
    });
    setTimeout(function () {
      //just delay
      cb();
    }, 0);
  }

  vfde.behaviours.registerBehaviour(video, 'stage.video');
})(window.vfde);
